import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectEnterprise } from 'src/app/core/store/core/core.selectors';
import { Enterprise } from 'src/app/models/Enterprise';
import endpoints from 'src/environments/endpoints';
import { SHOULD_ADD_TENANT } from '../../interceptors/auth';
import { DefaultResponse } from '../../models/DefaultResponse';
import { capitalize } from '../../utils/capitalize';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseService {
  //DEFAULT_SUBDOMAIN = 'teste';
  // DEFAULT_SUBDOMAIN = 'interbrasil';
  // DEFAULT_SUBDOMAIN = 'divicom';
  // DEFAULT_SUBDOMAIN = 'tecsaude';
  // DEFAULT_SUBDOMAIN = 'healthadm';
  // DEFAULT_SUBDOMAIN = 'gestao';
  DEFAULT_SUBDOMAIN = 'affix';
  constructor(private http: HttpClient, private store: Store) {}

  getEnterprise(): Observable<Enterprise> {
    const subdomain = this.getSubdomain();
    const baseURL = endpoints.enterprise;
    const url = `${baseURL}/${subdomain}`;

    return this.http
      .get<DefaultResponse<Enterprise>>(url, {
        context: new HttpContext().set(SHOULD_ADD_TENANT, false),
      })
      .pipe(map((res) => res?.data));
  }

  getTenantNameCapitalized(): Observable<string> {
    const enterprise = this.store.select(selectEnterprise);

    return enterprise.pipe(
      filter(Boolean),
      map((value) => value as Enterprise),
      map((res) => res?.tenantName),
      map((tenantName: string) => capitalize(tenantName))
    );
  }

  private getSubdomain(): string {
    return location.href.includes('http://localhost')
      ? this.DEFAULT_SUBDOMAIN
      : this.extractSubdomainFromURL(location.href);
  }

  private extractSubdomainFromURL(url: string): string {
    return (
      url?.replace('https://', '').replace('http://', '')?.split('.')?.[0] ?? ''
    );
  }
}
