<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [type]="type"
    autocomplete="new-password"
    [formControl]="control"
    (blur)="validateField()"
    (blur)="onBlur()"
    *ngIf="mask"
    [mask]="mask"
    [placeholder]="placeholder"
  />
  <input
    matInput
    [type]="type"
    autocomplete="new-password"
    [formControl]="control"
    (blur)="validateField()"
    (blur)="onBlur()"
    *ngIf="!mask"
    [placeholder]="placeholder"
  />

  <mat-error *ngIf="control.hasError('required')">Campo obrigatório</mat-error>
  <mat-error *ngIf="control.hasError('mask')"
    >Preencha este campo corretamente</mat-error
  >
  <mat-error *ngIf="control.hasError('maxlength')">
    Este campo deve ter até
    {{ control.getError("maxlength")?.requiredLength }} caracteres.
  </mat-error>
</mat-form-field>
