import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Provider } from 'src/app/models/Provider';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor() {}

  getAllProviders(): Observable<Provider[]> {
    return of([{ name: 'teste' }, { name: 'teste2' }]).pipe(delay(0));
  }
}
