import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-select-assistance-segment',
  templateUrl: './select-assistance-segment.component.html',
  styleUrls: ['./select-assistance-segment.component.scss'],
})
export class SelectAssistanceSegmentComponent
  extends CustomInput
  implements OnInit
{
  @Input() label = 'Tipo de Plano';
  override control = new UntypedFormControl([]);

  override ngOnInit(): void {
    this.registerChanges();
  }
}
