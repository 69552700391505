import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Subject, takeUntil } from 'rxjs';
import { AccessType } from 'src/app/core/enums/AccessType';
import { NotificationService } from 'src/app/core/services/notification';
import {
  clearBrokerProtheus,
  createBrokerRequest,
  loadBrokerByCpfRequest,
  loadCepCreateBrokerRequest,
} from 'src/app/core/store/auth/auth.actions';
import {
  selectBrokerProtheus,
  selectCepResponseCreateBroker,
} from 'src/app/core/store/auth/auth.selectors';
import FormChecker from 'src/app/core/utils/form-checker';
import { getDDDFromPhoneNumber } from 'src/app/core/utils/get-DDD-from-phone-number';
import { getPhoneNumberWithoutDDD } from 'src/app/core/utils/get-phone-number-without-DDD';
import { transformDateToISOFormat } from 'src/app/core/utils/transform-date-to-ISO-format';
import { CepIBGEResponse } from 'src/app/features/sale/interfaces/CepIBGEResponse';
import { BrokerProtheus } from '../../interfaces/BokerProtheus';
import { CreateBrokerPayload } from '../../interfaces/CreateBrokerPayload';

@Component({
  selector: 'app-create-broker-user-form',
  templateUrl: './create-broker-user-form.component.html',
  styleUrls: ['./create-broker-user-form.component.scss'],
})
export class CreateBrokerUserFormComponent implements OnDestroy, OnInit {
  @Input() accessType?: AccessType;

  formGroup = this.buildForm();

  showPass = false;
  isCapsLockOn!: boolean;
  lastSearchedCEP?: string;
  brokerProtheus?: BrokerProtheus;

  destroyed$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private fb: NonNullableFormBuilder,
    private notification: NotificationService
  ) {}

  get buttonType(): string {
    return this.brokerProtheus ? 'submit' : 'button';
  }

  ngOnInit(): void {
    this.setBrokerProtheus();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente');
      return;
    }
    this.createBrokerRequest();
  }

  getBrokerByCpf(): void {
    const cpfValue = this.formGroup.controls.cpf?.value;

    this.store.dispatch(loadBrokerByCpfRequest({ payload: cpfValue }));
    this.fillForm();
  }

  onBack(): void {
    if (!this.brokerProtheus) {
      this.navigateToLogin();
      return;
    }

    this.clearBrokerProtheusAndResetCpfControl();
  }

  onBlurQueryCep(): void {
    const cep = this.formGroup.controls.cep?.value;

    if (!cep) {
      return;
    }

    if (this.lastSearchedCEP !== cep) {
      this.store.dispatch(loadCepCreateBrokerRequest({ payload: cep }));
      this.loadResponseDataByCepRequest();
    }

    this.lastSearchedCEP = cep;
  }

  toggleShowPass(): void {
    this.showPass = !this.showPass;
  }

  setCapsLockOn(event: boolean): void {
    this.isCapsLockOn = event;
  }

  private clearBrokerProtheusAndResetCpfControl(): void {
    this.store.dispatch(clearBrokerProtheus());
    this.formGroup.controls.cpf.reset();
  }

  private navigateToLogin(): void {
    this.router.navigate(['autenticacao', 'entrar']);
  }

  private fillFormByBrokerProtheusResponse(broker?: BrokerProtheus): void {
    this.formGroup.patchValue({
      cpf: broker?.cpf,
      nomeCompleto: broker?.nomeCompleto,
      sexo: broker?.sexo,
      dataNascimento: broker?.dataNascimento?.trim(),
      cep: broker?.cep,
      endereco: broker?.endereco,
      numero: broker?.numero,
      complemento: broker?.complemento,
      bairro: broker?.bairro,
      cidade: broker?.cidade,
      estado: broker?.estado,
      dddTelefone: broker?.dddTelefone,
      telefone: broker?.telefone,
      dddCelular: broker?.dddCelular,
      celular: broker?.celular,
      login: broker?.login,
      email: broker?.email,
    });
  }

  private fillForm(): void {
    this.store
      .select(selectBrokerProtheus)
      .pipe(takeUntil(this.destroyed$), filter(Boolean))
      .subscribe((broker) => {
        this.fillFormByBrokerProtheusResponse(broker);
      });
  }

  private fillFormByCepResponseData(response?: CepIBGEResponse): void {
    this.formGroup.patchValue({
      endereco: response?.logradouro,
      complemento: response?.complemento,
      bairro: response?.bairro,
      cidade: response?.localidade,
      estado: response?.uf,
    });
  }

  private loadResponseDataByCepRequest(): void {
    this.store
      .select(selectCepResponseCreateBroker)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((cepResponse) => {
        this.fillFormByCepResponseData(cepResponse);
      });
  }

  private setBrokerProtheus(): void {
    this.store
      .select(selectBrokerProtheus)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((broker) => {
        this.brokerProtheus = broker;
      });
  }

  private createBrokerRequest(): void {
    const payload = this.getCreateBrokerPayload();

    this.store.dispatch(createBrokerRequest({ payload }));
  }

  private buildForm() {
    return this.fb.group({
      cpf: ['', [Validators.required, FormChecker.cpf]],
      nomeCompleto: ['', [Validators.required, FormChecker.fullName]],
      sexo: ['', Validators.required],
      dataNascimento: ['', Validators.required],
      cep: ['', Validators.required],
      endereco: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      estado: ['', Validators.required],
      dddTelefone: [''],
      telefone: [''],
      dddCelular: [''],
      celular: ['', Validators.required],
      login: [
        '',
        [Validators.required, FormChecker.login, Validators.minLength(4)],
      ],
      email: ['', Validators.required],
      senha: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  private getCreateBrokerPayload(): CreateBrokerPayload {
    const form = this.formGroup.getRawValue();

    const dataNascimento = transformDateToISOFormat(form.dataNascimento);

    return {
      nomeCompleto: form.nomeCompleto,
      cpf: form.cpf,
      dataNascimento,
      sexo: form.sexo,
      dddCelular: getDDDFromPhoneNumber(form.celular),
      celular: getPhoneNumberWithoutDDD(form.celular),
      dddTelefone: getDDDFromPhoneNumber(form.telefone),
      telefone: getPhoneNumberWithoutDDD(form.telefone),
      email: form.email,
      cep: form.cep,
      endereco: form.endereco,
      numero: form.numero,
      complemento: form.complemento,
      bairro: form.bairro,
      cidade: form.cidade,
      municipio: form.cidade,
      estado: form.estado,
      login: form.login.trim(),
      senha: form.senha.trim(),
    };
  }
}
