import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectIsLoggedIn } from '../store/auth/auth.selectors';
import { AppState } from '../store/core/core.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectIsLoggedIn).pipe(
      map((isLoggedIn) => {
        const isAuthentication = route.url[0].path === 'autenticacao';

        if (isLoggedIn && isAuthentication) {
          return this.router.parseUrl('inicio');
        }

        if (isAuthentication) {
          return true;
        }

        if (isLoggedIn) {
          return true;
        }

        return this.router.parseUrl('autenticacao/entrar');
      })
    );
  }
}
