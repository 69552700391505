import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string): string {
    const onlyNumbers = phoneNumber.replace(/\D/g, '');

    const numberWithPharenteses = onlyNumbers.replace(
      /^(\d{2})(\d)/g,
      '($1) $2'
    );

    const resultWithHifen = numberWithPharenteses.replace(
      /(\d)(\d{4})$/,
      '$1-$2'
    );

    return resultWithHifen;
  }
}
