import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-select-type-plan',
  templateUrl: './card-select-type-plan.component.html',
  styleUrls: ['./card-select-type-plan.component.scss'],
})
export class CardSelectTypePlanComponent {
  @Input() title!: string;
  @Input() text!: string;
  @Input() linkTo!: string;
}
