import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HeaderComponent } from './header';
import { FooterComponent } from './footer';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CartPopupComponent } from './cart-popup/cart-popup.component';
import { ComponentsModule } from '../core/components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

const COMPONENTS = [HeaderComponent, FooterComponent, CartPopupComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    PipesModule,
    CommonModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    ComponentsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
  ],
  exports: [...COMPONENTS],
})
export class SharedModule {}
