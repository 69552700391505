import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { CustomInput } from '../CustomInput';
import { Store } from '@ngrx/store';
import { selectUFs } from 'src/app/core/store/inputs/inputs.selectors';
@Component({
  selector: ' app-select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.scss'],
})
export class SelectStateComponent extends CustomInput implements OnInit {
  states$ = this.store.select(selectUFs);

  constructor(@Self() @Optional() ngControl: NgControl, private store: Store) {
    super(ngControl);
  }

  override ngOnInit(): void {
    this.registerChanges();
  }
}
