import { SystemSegment } from 'src/app/models/SystemSegment';

export const convertSegmentToBranchCode = (
  segment?: SystemSegment
): string | null => {
  if (!segment) {
    return null;
  }
  return segment === 'PF' ? '01' : '02';
};
