import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private confirmConfig: SweetAlertOptions = {
    icon: 'question',
    confirmButtonText: 'Confirmar',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    // confirmButtonColor: '$default-secondary-color',
    showCloseButton: true,
    customClass: {
      cancelButton: 'Swal__cancelButton',
    },
  };

  constructor(private toastr: ToastrService) {
    const toastrConfig = this.toastr.toastrConfig;

    toastrConfig.preventDuplicates = true;
    toastrConfig.resetTimeoutOnDuplicate = true;
    toastrConfig.timeOut = 5000;
    toastrConfig.progressBar = true;
    toastrConfig.easing = 'easeOutBounce';
  }

  confirm(options: SweetAlertOptions): Observable<boolean> {
    return from(
      Swal.fire({
        ...this.confirmConfig,
        ...options,
      })
    ).pipe(map((res) => res?.isConfirmed));
  }

  success(text: string, title = 'Tudo certo!'): void {
    this.toastr.success(text, title);
  }

  info(title: string, text = 'Informação'): void {
    this.toastr.info(title, text);
  }
}
