import { Component, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ClientService } from 'src/app/core/services/client';
import { TargetAudience } from 'src/app/models/TargetAudience';
import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-select-target-audience',
  templateUrl: './select-target-audience.component.html',
  styleUrls: ['./select-target-audience.component.scss'],
})
export class SelectTargetAudienceComponent
  extends CustomInput
  implements OnInit, OnDestroy
{
  targetAudiences!: TargetAudience[];

  destroyed$ = new Subject<void>();

  constructor(
    @Self() @Optional() ngControl: NgControl,
    private clientService: ClientService
  ) {
    super(ngControl);
  }

  override ngOnInit(): void {
    this.getTargetAudiences();
    this.registerChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getTargetAudiences(): void {
    this.clientService
      .getTargetAudiences()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.targetAudiences = response;
      });
  }
}
