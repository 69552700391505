<div class="reset-pass-container">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-card class="m-4shadow">
      <h1 class="reset-pass-title">Restaurar senha</h1>
      <p class="text-muted mb-3">Simulador de planos</p>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Nova senha</mat-label>

        <input
          matInput
          type="password"
          [type]="showPass ? 'text' : 'password'"
          formControlName="password"
        />
        <mat-icon
          [class.text-success]="formGroup.controls.password.valid"
          matSuffix
          *ngIf="!showPass"
          (click)="toggleShowPass()"
        >
          lock
        </mat-icon>
        <mat-icon
          [class.text-success]="formGroup.controls.password.valid"
          matSuffix
          *ngIf="showPass"
          (click)="toggleShowPass()"
          >lock_open</mat-icon
        >

        <mat-error *ngIf="formGroup.controls.password.hasError('required')">
          Senha é obrigatória</mat-error
        >
        <mat-error *ngIf="formGroup.controls.password.hasError('different')">
          As senhas não conferem
        </mat-error>
        <mat-error *ngIf="formGroup.controls.password.hasError('minlength')">
          A senha deve ter no mínimo 8 caracteres
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Confirmação da nova senha</mat-label>
        <input
          matInput
          type="password"
          [type]="showPass ? 'text' : 'password'"
          formControlName="passwordConfirmation"
        />

        <mat-error
          *ngIf="formGroup.controls.passwordConfirmation.hasError('required')"
        >
          Confirmação da senha é obrigatória</mat-error
        >
        <mat-error
          *ngIf="formGroup.controls.passwordConfirmation.hasError('different')"
        >
          As senhas não conferem
        </mat-error>
        <mat-error
          *ngIf="formGroup.controls.passwordConfirmation.hasError('minlength')"
        >
          A senha deve ter no mínimo 8 caracteres
        </mat-error>
      </mat-form-field>

      <div class="d-flex justify-content-between align-items-center">
        <a routerLink="/autenticacao/entrar" class="a-tag-link a-tag-link-text">
          Lembrou a senha?
        </a>
        <app-button type="submit" label="Alterar"></app-button>
      </div>
    </mat-card>
  </form>
</div>
