<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="row">
    <!-- CPF -->
    <mat-form-field appearance="outline" class="col-lg-3 col-md-6">
      <mat-label>CPF</mat-label>
      <input
        matInput
        type="text"
        inputmode="numeric"
        formControlName="cpf"
        mask="000.000.000-00"
      />
      <mat-icon
        [class.text-success]="formGroup.controls.cpf.valid"
        *ngIf="formGroup.controls.cpf.value"
        matSuffix
      >
        {{ formGroup.controls.cpf.hasError("invalidCpf") ? "close" : "done" }}
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.cpf.hasError('required')">
        CPF é obrigatório
      </mat-error>

      <mat-error *ngIf="formGroup.controls.cpf.hasError('invalidCpf')">
        Digite um CPF válido
      </mat-error>
    </mat-form-field>

    <!-- Nome -->
    <mat-form-field appearance="outline" class="col-lg-3 col-md-6">
      <mat-label>Nome</mat-label>
      <input matInput type="text" formControlName="name" />

      <mat-icon
        [class.text-success]="formGroup.controls.name.valid"
        *ngIf="formGroup.controls.name.value"
        matSuffix
      >
        person
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.name.hasError('required')">
        Nome é obrigatório
      </mat-error>

      <mat-error *ngIf="formGroup.controls.name.hasError('minlength')">
        O nome deve ter no mínimo 3 caracteres
      </mat-error>
    </mat-form-field>

    <!-- Telefone -->
    <mat-form-field class="col-lg-3 col-md-6">
      <mat-label> Telefone com DDD </mat-label>
      <input
        type="text"
        inputmode="numeric"
        matInput
        formControlName="telephone"
        [mask]="phoneMask"
      />

      <mat-icon
        [class.text-success]="formGroup.controls.telephone.valid"
        *ngIf="formGroup.controls.telephone.value"
        matSuffix
      >
        {{ phoneIcon }}
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.telephone.hasError('mask')">
        Preencha este campo corretamente
      </mat-error>
      <mat-error *ngIf="formGroup.controls.telephone.hasError('required')">
        Telefone é obrigatório
      </mat-error>
    </mat-form-field>

    <!-- E-mail -->
    <mat-form-field appearance="outline" class="col-lg-3 col-md-6">
      <mat-label>E-mail</mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-icon
        [class.text-success]="formGroup.controls.email.valid"
        *ngIf="formGroup.controls.email.value"
        matSuffix
      >
        email
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.email.hasError('required')">
        E-mail é obrigatório
      </mat-error>

      <mat-error *ngIf="formGroup.controls.email.hasError('email')">
        Digite um e-mail válido
      </mat-error>
    </mat-form-field>

    <!-- Password -->
    <mat-form-field appearance="outline" class="col-lg-6 col-md-6">
      <mat-label>Senha</mat-label>
      <input
        matInput
        [type]="showPass ? 'text' : 'password'"
        formControlName="password"
      />
      <mat-icon
        [class.text-success]="formGroup.controls.password.valid"
        matSuffix
        *ngIf="!showPass && this.formGroup.controls.password.value"
        (click)="toggleShowPass()"
      >
        lock
      </mat-icon>

      <mat-icon
        [class.text-success]="formGroup.controls.password.valid"
        matSuffix
        *ngIf="showPass && this.formGroup.controls.password.value"
        (click)="toggleShowPass()"
      >
        lock_open
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.password?.hasError('required')">
        Senha é obrigatória
      </mat-error>

      <mat-error *ngIf="formGroup.controls.password?.hasError('minlength')">
        A senha deve ter ao menos 8 caracteres
      </mat-error>
    </mat-form-field>

    <!-- Confirm Password -->
    <mat-form-field appearance="outline" class="col-lg-6 col-md-6">
      <mat-label>Confirme a senha</mat-label>
      <input
        matInput
        [type]="showPass ? 'text' : 'password'"
        formControlName="confirmPassword"
      />
      <mat-error
        *ngIf="formGroup.controls.confirmPassword.hasError('required')"
      >
        Confirmação da senha é obrigatória
      </mat-error>

      <mat-error
        *ngIf="formGroup.controls.confirmPassword.hasError('differentPass')"
      >
        As senhas não coincidem
      </mat-error>
      <mat-error
        *ngIf="formGroup.controls.confirmPassword.hasError('minlength')"
      >
        A senha deve ter ao menos 8 caracteres
      </mat-error>
    </mat-form-field>
  </div>

  <div class="d-flex justify-content-between my-1">
    <button
      mat-stroked-button
      type="button"
      class="back-button"
      routerLink="/autenticacao/entrar"
    >
      Voltar
    </button>
    <app-button type="submit" label="Salvar"></app-button>
  </div>
</form>

<small
  appCapsLock
  (capsLockOn)="setCapsLockOn($event)"
  [class.d-none]="!isCapsLockOn"
>
  CAPS LOCK ATIVADO
</small>
