<form
  class="d-flex justify-content-center"
  [class.d-none]="ageRange.initialRange === 0 && ageRange.finalRange === 999"
>
  <mat-form-field class="w-100" appearance="outline">
    <mat-label *ngIf="ageRange.finalRange !== 999">
      {{ ageRange.initialRange }} a {{ ageRange.finalRange }} anos
    </mat-label>
    <mat-label *ngIf="ageRange.finalRange === 999">
      {{ ageRange.initialRange }} anos ou mais
    </mat-label>
    <input disabled matInput />
    <div class="d-flex" matSuffix>
      <mat-icon
        class="a-tag-link color-icon-input-age-range"
        (click)="decrement()"
      >
        remove
      </mat-icon>
      <input
        #input
        class="value-input"
        [ngModel]="control.value.lifeAmount"
        (ngModelChange)="setControlValue(input, $event)"
        mask="9999"
        name="input"
        autocomplete="off"
      />
      <mat-icon
        class="a-tag-link color-icon-input-age-range"
        (click)="increment()"
      >
        add
      </mat-icon>
    </div>
  </mat-form-field>
</form>
