import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from 'src/app/core/utils/capitalize';
import { capitalizeAll } from 'src/app/core/utils/capitalize-all';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string, all = false): string {
    return all ? capitalizeAll(value) : capitalize(value);
  }
}
