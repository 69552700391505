import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InputsState } from './inputs.interfaces';

const selectFeature = createFeatureSelector<InputsState>('inputs');

export const selectUFs = createSelector(selectFeature, (state) => state.ufs);

export const selectCities = createSelector(
  selectFeature,
  (state) => state.cities
);
