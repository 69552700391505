<div
  class="fixed-bottom bg-light cookie-acceptance-container"
  [class.show]="showCookieAcceptance$ | async"
>
  <div class="container-fluid">
    <div class="row p-4">
      <div class="col-md-6 col-sm-12 d-flex align-items-center text-justify">
        <p>
          A {{ (enterprise$ | async)?.customerName }} utiliza cookies para
          permitir o início de sessão segura, memorizar suas preferências, gerir
          a sessão, fazer análises e relatórios estatísticos sobre o uso do
          site, otimizar as funcionalidades e oferecer conteúdo adequado aos
          seus interesses. Para saber mais sobre cookies, acesse nossa
          <a
            class="text-primary"
            routerLink="/politicas/politica-de-privacidade"
            >Política de Privacidade.</a
          >
        </p>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="d-flex align-items-center flex-column">
          <a
            routerLink="/politicas/politica-de-seguranca-da-informacao"
            mat-raised-button
            class="w-350 mt-3"
            color="secondary"
          >
            Política de segurança da informação
          </a>
          <a
            routerLink="/politicas/politica-de-privacidade"
            mat-raised-button
            class="w-350 mt-3"
            color="secondary"
          >
            Política de privacidade
          </a>
          <button
            mat-raised-button
            class="w-350 mt-3"
            color="primary"
            (click)="onAgreeClick()"
          >
            Concordar e fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
