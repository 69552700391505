import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import FormChecker from 'src/app/core/utils/form-checker';
import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-input-cpf',
  templateUrl: './input-cpf.component.html',
  styleUrls: ['./input-cpf.component.scss'],
})
export class InputCpfComponent extends CustomInput {
  @Input() label = 'CPF';
  @Input() icon: string | undefined;
  @Output() blured = new EventEmitter();

  override control = new UntypedFormControl(null, FormChecker.cpf);
  override errorList = ['invalidCpf'];

  onBlur(): void {
    this.blured.emit();
  }
}
