import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, Subject, takeUntil } from 'rxjs';
import { AccessType } from 'src/app/core/enums/AccessType';
import {
  requestBrokerCredentialsRequest,
  requestClientPasswordRequest,
} from 'src/app/core/store/auth/auth.actions';
import {
  selectAccessType,
  selectBrokerCPF,
} from 'src/app/core/store/auth/auth.selectors';
import { AppState } from 'src/app/core/store/core/core.interfaces';
import { showNotificationInfo } from 'src/app/core/store/notification/notification.actions';
import FormChecker from 'src/app/core/utils/form-checker';

@Component({
  selector: 'app-request-pass',
  templateUrl: './request-pass.component.html',
  styleUrls: ['./request-pass.component.scss'],
})
export class RequestPassComponent implements OnInit, OnDestroy {
  formGroup!: FormGroup<{
    cpf: FormControl<string>;
    email: FormControl<string>;
  }>;

  accessType?: AccessType;

  destroyed$ = new Subject<void>();

  brokerCPF?: string;

  constructor(
    private store: Store<AppState>,
    private fb: NonNullableFormBuilder
  ) {}

  get formControlName(): string {
    return this.accessType === AccessType.Broker ? 'CPF' : 'e-mail';
  }

  ngOnInit(): void {
    this.buildForm();
    this.setBrokerCPF();
    this.fillForm();
    this.setAccesstype();
    this.setFormValidators();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.store.dispatch(
        showNotificationInfo({
          payload: `Preencha o ${this.formControlName} corretamente.`,
        })
      );
      return;
    }

    if (this.accessType === AccessType.Broker) {
      this.requestBrokerCredentials();
      return;
    }

    this.requestClientPassword();
  }

  private setBrokerCPF(): void {
    this.store
      .select(selectBrokerCPF)
      .pipe(takeUntil(this.destroyed$), filter(Boolean))
      .subscribe((CPF) => {
        this.brokerCPF = CPF;
      });
  }

  private requestBrokerCredentials(): void {
    const cpf = this.getCPFValue();

    this.store.dispatch(requestBrokerCredentialsRequest({ payload: cpf }));
  }

  private requestClientPassword(): void {
    const email = this.getEmailValue();

    this.store.dispatch(requestClientPasswordRequest({ payload: email }));
  }

  private setAccesstype(): void {
    this.store
      .select(selectAccessType)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((accessType) => {
        this.accessType = accessType;
      });
  }

  private buildForm(): void {
    this.formGroup = this.fb.group({
      cpf: this.fb.control('', [Validators.required, FormChecker.cpf]),
      email: this.fb.control('', [Validators.required, FormChecker.email]),
    });
  }

  private fillForm(): void {
    this.formGroup.patchValue({
      cpf: this.brokerCPF,
    });
  }

  private setFormValidators(): void {
    const form = this.formGroup.controls;

    if (this.accessType === AccessType.Broker) {
      form.email.setValidators(null);
      form.cpf.setValidators([Validators.required, FormChecker.cpf]);
      return;
    }

    form.cpf.setValidators(null);
    form.email.setValidators([Validators.required, Validators.email]);
  }

  private getEmailValue(): string {
    return this.formGroup.controls.email?.value;
  }

  private getCPFValue(): string {
    return this.formGroup.controls.cpf?.value;
  }
}
