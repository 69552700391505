import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import {
  CoreActions,
  showCookieAcceptance,
  cookieAcceptanceSuccess,
  setEnterprise,
  loadEnterprise,
  setAppVersion,
  setIsSaleFlow,
  goToSimulationPage,
  setShowHeader,
  setPlanToCartRequest,
  setPlanToCartSuccess,
  setPlanToCartError,
  setShoppingCart,
  loadCartRequest,
  removePlanCartRequest,
  removePlanCartSuccess,
  removePlanCartError,
  setSimulationStep,
  hideCartPopup,
  clearCartRequest,
  clearCartSuccess,
  clearCartError,
  initSaleRequest,
  setShowMenuList,
} from './core.actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './core.interfaces';
import {
  selectEnterprise,
  selectHasEnterprise,
  selectIsAppVersionUpdated,
  selectIsCookieAccepted,
  selectShowCartPopup,
  selectSystemSegment,
} from './core.selectors';
import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { CookieService } from 'src/app/core/services/cookie';
import { LayoutService } from 'src/app/core/services/layout';
import packageJsonInfo from '../../../../../package.json';
import { showNotificationInfo } from '../notification/notification.actions';
import { LoaderService } from '../../services/loader';
import { Router } from '@angular/router';
import { ROUTER_NAVIGATION, ROUTER_REQUEST } from '@ngrx/router-store';
import { NotificationService } from '../../services/notification';
import { ShoppingCartService } from '../../services/shopping-cart/shopping-cart.service';
import { initCreateSimulation } from 'src/app/features/simulation/store/simulation.actions';
import { CepService } from 'src/app/features/sale/services/cep/cep.service';

@Injectable({
  providedIn: 'root',
})
export class CoreEffects {
  hideHeaderIfAuthentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map((action: any) => action.payload.event.url),
      map((urlTo: string) => urlTo.startsWith('/autenticacao')),
      map((isAuthOrSignature) => setShowHeader({ payload: !isAuthOrSignature }))
    )
  );
  hideMenuListIfSignatureFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map((action: any) => action.payload.event.url),
      map((urlTo: string) => urlTo.startsWith('/assinatura')),
      map((isAuthOrSignature) =>
        setShowMenuList({ payload: !isAuthOrSignature })
      )
    )
  );

  goToSimulationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goToSimulationPage),
        switchMap(() => this.store.select(selectSystemSegment).pipe(first())),
        tap((systemSegment) => {
          if (systemSegment === 'PF') {
            this.router.navigate(['simulacao/individual']);
            return;
          }

          if (systemSegment === 'Adesao') {
            this.router.navigate(['simulacao/adesao']);
            return;
          }

          if (systemSegment === 'PME') {
            this.router.navigate(['simulacao/empresarial']);
            return;
          }

          this.router.navigate(['inicio']);
        })
      ),
    { dispatch: false }
  );

  setIsSaleFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      map((action: any) => action.payload.event.url),
      map((urlTo: string) => urlTo.startsWith('/venda')),
      map((isSaleFlow) => setIsSaleFlow({ payload: isSaleFlow }))
    )
  );

  hideCartOnNavigateOrStepChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION, setSimulationStep, setPlanToCartRequest),
      switchMap(() => this.store.select(selectShowCartPopup).pipe(first())),
      filter(Boolean),
      map(() => hideCartPopup())
    )
  );

  initSaleRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initSaleRequest),
      switchMap(() =>
        this.notification.confirm({
          title: `Deseja prosseguir com a contratação?`,
        })
      ),
      filter(Boolean),
      map(() => initCreateSimulation()),
      tap(() => this.router.navigate(['/venda']))
    )
  );

  showCookieAcceptance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.store.select(selectIsCookieAccepted)),
      filter((isCookieAccepted) => !isCookieAccepted),
      delay(500),
      map(() => showCookieAcceptance())
    )
  );

  acceptCookie$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.CookieAccepted),
      switchMap(() => this.cookieService.acceptCookie()),
      map(() => cookieAcceptanceSuccess())
    )
  );

  initEnterprise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.store.select(selectEnterprise).pipe(take(1))),
      tap((e) => e && this.layoutService.setEnterpriseCssMainColors(e)),
      switchMap(() =>
        this.store.select(selectIsAppVersionUpdated).pipe(take(1))
      ),
      switchMap((isUpdated) =>
        this.store.select(selectHasEnterprise).pipe(
          take(1),
          map((hasEnterprise) => !hasEnterprise || !isUpdated)
        )
      ),
      filter(Boolean),
      map(() => loadEnterprise())
    )
  );

  loadEnterprise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.LoadEnterprise),
      tap(() => this.loader.show('Carregando dados da empresa...', true)),
      switchMap(() => this.enterpriseService.getEnterprise()),
      tap(() => this.loader.hide()),
      tap((e) => this.layoutService.setEnterpriseCssMainColors(e)),
      map((enterprise) => setEnterprise({ payload: enterprise }))
    )
  );

  setProjectVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.SetEnterprise),
      map(() => setAppVersion({ payload: packageJsonInfo.version }))
    )
  );

  setPlanToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPlanToCartRequest),
      tap(() => this.loader.show('Adicionando plano no carrinho...')),
      switchMap((action) =>
        this.shoppingCartService.addPlan(action.payload).pipe(
          map(() => setPlanToCartSuccess()),
          catchError(() => of(setPlanToCartError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  loadCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCartRequest),
      switchMap(() =>
        this.shoppingCartService
          .getShoppingCart()
          .pipe(
            map((shoppingCart) => setShoppingCart({ payload: shoppingCart }))
          )
      )
    )
  );

  removePlanCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removePlanCartRequest),
      tap(() => this.loader.show('Removendo plano do carrinho...')),
      switchMap((action) =>
        this.shoppingCartService.deletePlan(action.payload).pipe(
          map(() => removePlanCartSuccess()),
          catchError(() => of(removePlanCartError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  reloadShoppingCartWhenRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removePlanCartSuccess, clearCartSuccess, setPlanToCartSuccess),
      map(() => loadCartRequest())
    )
  );

  clearCartRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearCartRequest),
      tap(() => this.loader.show('Limpando carrinho...')),
      switchMap(() =>
        this.shoppingCartService.clearCart().pipe(
          map(() => clearCartSuccess()),
          catchError(() => of(clearCartError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  showClearCartErrorNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearCartError),
      map(() => 'Erro ao limpar o carrinho. Tente novamente'),
      map((payload) => showNotificationInfo({ payload }))
    )
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private loader: LoaderService,
    private cepService: CepService,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private layoutService: LayoutService,
    private notification: NotificationService,
    private enterpriseService: EnterpriseService,
    private shoppingCartService: ShoppingCartService
  ) {}
}
