<mat-form-field appearance="outline" class="w-100" (click)="validateField()">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    placeholder="Selecione a Cobertura"
    (selectionChange)="changed.emit($event)"
    [formControl]="control"
  >
    <mat-option value="01">Nacional</mat-option>
    <mat-option value="03">Grupo de Municípios</mat-option>
    <mat-option value="04">Municipal</mat-option>
  </mat-select>
  <mat-error> Campo obrigatório </mat-error>
</mat-form-field>
