import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/core/core.interfaces';
import { selectEnterprise } from 'src/app/core/store/core/core.selectors';

import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  appVersion = environment.appVersion;
  faPhoneAlt = faPhoneAlt as IconProp;
  faEnvelope = faEnvelope as IconProp;
  enterprise$ = this.store.select(selectEnterprise);

  currentYear = new Date().getFullYear();

  constructor(private store: Store<AppState>) {}
}
