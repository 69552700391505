/* eslint-disable radix */
/* eslint-disable max-len */
import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { format, isAfter, isValid, parse } from 'date-fns';
import { onlyNumbers } from './only-numbers';

export default class FormChecker {
  static email(control: AbstractControl): any {
    if (!control.value) {
      return null;
    }

    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = re.test(String(control.value).toLowerCase());

    if (isValidEmail) {
      return null;
    }

    return { invalidEmail: true };
  }

  static cpf(control: AbstractControl): any {
    if (!control.value) {
      return null;
    }

    const validateCPF = (cpfString: number | string): boolean => {
      if (typeof cpfString === 'number') {
        cpfString = cpfString.toString();
      }

      const strCPF = cpfString.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
      let sum: number;
      let rest: number;
      sum = 0;
      if (strCPF === '00000000000') {
        return false;
      }
      for (let tie = 1; tie <= 9; tie++) {
        sum = sum + parseInt(strCPF.substring(tie - 1, tie), 0) * (11 - tie);
      }
      rest = (sum * 10) % 11;
      if (rest === 10 || rest === 11) {
        rest = 0;
      }
      if (rest !== parseInt(strCPF.substring(9, 10), 0)) {
        return false;
      }
      sum = 0;
      for (let tie = 1; tie <= 10; tie++) {
        sum = sum + parseInt(strCPF.substring(tie - 1, tie), 0) * (12 - tie);
      }
      rest = (sum * 10) % 11;
      if (rest === 10 || rest === 11) {
        rest = 0;
      }
      if (rest !== parseInt(strCPF.substring(10, 11), 0)) {
        return false;
      }
      return true;
    };

    const isValidCPF = validateCPF(control.value);

    if (isValidCPF) {
      return null;
    }

    return {
      invalidCpf: true,
    };
  }

  static cnpj(control: AbstractControl): any {
    if (!control.value) {
      return null;
    }

    const validateCNPJ = (value: number | string): boolean => {
      if (!value) {
        return true;
      }

      if (typeof value !== 'string') {
        return false;
      }

      const numbers = onlyNumbers(String(value)).split('').map(Number);

      if (numbers.length !== 14) {
        return false;
      }

      const items = [...new Set(numbers)];
      if (items.length === 1) {
        return false;
      }

      const calc = (x: any) => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;

        for (let i = x; i >= 1; i--) {
          const n = slice[x - i];
          sum += n * factor--;
          if (factor < 2) {
            factor = 9;
          }
        }

        const result = 11 - (sum % 11);

        return result > 9 ? 0 : result;
      };

      const digits = numbers.slice(12);

      const digit0 = calc(12);
      if (digit0 !== digits[0]) {
        return false;
      }

      const digit1 = calc(13);
      return digit1 === digits[1];
    };

    const isValidCNPJ = validateCNPJ(control.value);

    if (isValidCNPJ) {
      return null;
    }

    return {
      invalidCnpj: false,
    };
  }

  static fullName(control: AbstractControl): any {
    if (!control.value) {
      return null;
    }

    const hasNumber = (str: string) => /\d/.test(str);
    const hasSpecialCharacters = (str: string) =>
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
    const hasOneLetter = (str: string): boolean => str.length === 1;

    const name = control.value as string;
    const words = name.split(' ').filter((word) => word.trim().length > 0);
    const wordsAmount = words.length;
    const firstName = words[0];
    const lastName = words[words.length - 1];

    if (hasNumber(name)) {
      return { invalidName: 'Este campo não pode ter números' };
    }

    if (hasSpecialCharacters(name)) {
      return { invalidName: 'Este campo não pode ter caracteres especiais' };
    }

    if (wordsAmount <= 1) {
      return { invalidName: 'Este campo não pode ter apenas o primeiro nome' };
    }

    if (words.every(hasOneLetter)) {
      return { invalidName: 'Os nomes não poder ter apenas um caractere' };
    }

    if (hasOneLetter(firstName)) {
      const allowedLetters = 'DdIiÍíìÌÓÒÔOóòôoUÛÚÙúùuÝYy';
      if (!allowedLetters.includes(firstName)) {
        return {
          invalidName: 'O primeiro nome não pode ter apenas 1 caractere',
        };
      }
    }
    if (hasOneLetter(lastName)) {
      const allowedLetters = 'IÌÍíìiOÓÒÔoóòôÚÙÛUuÝYy';
      if (!allowedLetters.includes(lastName)) {
        return { invalidName: 'O último nome não pode ter apenas 1 caractere' };
      }
    }
    if (words.length >= 3) {
      const wordsWhithoutFirstAndLast = words.slice(1, words.length - 1);
      if (wordsWhithoutFirstAndLast.some(hasOneLetter)) {
        return {
          invalidName: 'O nome do meio não pode ter apenas 1 caractere',
        };
      }
    }
    return null;
  }

  static strongPassword(control: AbstractControl): ValidationErrors | null {
    const value = (control.value ?? '') as string;

    if (value.length < 8) {
      return {
        invalidPassword: 'A senha deve ter no mínimo 8 caracteres',
      };
    }

    if (!value.match(/[A-Z]/g)) {
      return {
        invalidPassword: 'A senha deve ter um caractere maiúsculo',
      };
    }

    if (!value.match(/[0-9]/g)) {
      return {
        invalidPassword: 'A senha deve ter um número',
      };
    }

    if (!value.match(/[^A-z,0-9]/g)) {
      return {
        invalidPassword: 'A senha deve ter um caractere especial',
      };
    }

    if (value.match(/(?<=\w)\s+(?=\w)/g)) {
      return {
        invalidLogin: 'A senha não pode ter espaços em branco',
      };
    }

    if (value.match(/[!"#$%&'()*+,/:;<=>?@[\]^~]/g)) {
      return {
        invalidLogin:
          'Senha com caractere especial não permitido. Permitidos: hífen(-), underline(_) ou ponto(.)',
      };
    }

    return null;
  }

  public static brazilianDate(
    control: UntypedFormControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const value = onlyNumbers(control.value);

    let date: Date | null = null;

    try {
      date = parse(value, 'ddMMyyyy', new Date());
    } catch {
      return { brazilianDate: true };
    }

    if (isValid(date)) {
      return null;
    }

    return { brazilianDate: true };
  }

  static expirationDate(control: AbstractControl): ValidationErrors | null {
    const dateFormat = 'MM/yyyy';
    const now = new Date();
    const date = parse(control.value, dateFormat, now);
    const currentDate = parse(format(now, dateFormat), dateFormat, now);

    if (isAfter(currentDate, date)) {
      return {
        invalidExpirationDate: true,
      };
    }

    return null;
  }

  static login(control: AbstractControl): ValidationErrors | null {
    const value = (control.value ?? '') as string;

    if (value.match(/(?<=\w)\s+(?=\w)/g)) {
      return {
        invalidLogin: 'O login não pode ter espaços em branco',
      };
    }

    if (value.match(/[!"#$%&'()*+,/:;<=>?@[\]^~]/g)) {
      return {
        invalidLogin:
          'Caractere especial não permitido. Permitidos: hífen(-), underline(_) ou ponto(.)',
      };
    }

    return null;
  }
}
