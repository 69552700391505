<mat-form-field appearance="outline" class="w-100">
  <mat-label>
    {{ label }}
  </mat-label>

  <input
    matInput
    type="text"
    inputmode="numeric"
    autocomplete="off"
    [formControl]="control"
    (blur)="validateField()"
    currencyMask
    [options]="{ prefix: 'R$ ' }"
  />

  <mat-error *ngIf="control.hasError('required')">Campo obrigatório</mat-error>
</mat-form-field>
