import { createReducer, on } from '@ngrx/store';
import { BrokerProtheus } from 'src/app/features/auth/interfaces/BokerProtheus';
import { SignInResponse } from 'src/app/features/auth/interfaces/SignInResponse';
import { CepIBGEResponse } from 'src/app/features/sale/interfaces/CepIBGEResponse';
import { AccessType } from '../../enums/AccessType';
import { DecodedToken } from '../../models/DecodedToken';
import { Prop } from '../../models/Prop';
import {
  clearAuthState,
  clearBrokerProtheus,
  loadBrokerByCpfRequest,
  loadBrokerByCpfSuccess,
  loadCepCreateBrokerRequest,
  loadCepCreateBrokerSuccess,
  logout,
  setAccessType,
  signInSuccess,
} from './auth.actions';
import { AuthState } from './auth.interfaces';

const initialState: AuthState = {};

const _setAccessType = (
  state: AuthState,
  action: Prop<AccessType>
): AuthState => ({
  ...state,
  accessType: action.payload,
  brokerCPF: undefined,
});

const _signInSuccess = (
  state: AuthState,
  action: Prop<{ signInResponse: SignInResponse; decodedToken: DecodedToken }>
): AuthState => ({
  ...state,
  accessToken: action.payload.signInResponse.accessToken,
  decodedToken: action.payload.decodedToken,
  brokerCode: action.payload.signInResponse?.brokerCode,
});

const _logout = (state: AuthState): AuthState => ({
  ...state,
  accessToken: undefined,
  decodedToken: undefined,
  accessType: undefined,
});

const _loadCepCreateBrokerRequest = (state: AuthState): AuthState => ({
  ...state,
  cepResponseBrokerProtheus: undefined,
});

const _loadCepCreateBrokerSuccess = (
  state: AuthState,
  action: Prop<CepIBGEResponse>
): AuthState => ({
  ...state,
  cepResponseBrokerProtheus: action.payload,
});

const _loadBrokerByCpfRequest = (
  state: AuthState,
  action: Prop<string>
): AuthState => ({
  ...state,
  brokerCPF: action.payload,
});

const _loadBrokerByCpfSuccess = (
  state: AuthState,
  action: Prop<BrokerProtheus>
): AuthState => ({
  ...state,
  brokerProtheus: action.payload,
});

const _clearBrokerProtheus = (state: AuthState): AuthState => ({
  ...state,
  brokerProtheus: undefined,
});

const _clearAuthState = (): AuthState => ({});

export const authReducer = createReducer(
  initialState,
  on(setAccessType, _setAccessType),
  on(signInSuccess, _signInSuccess),
  on(logout, _logout),
  on(clearAuthState, _clearAuthState),
  on(loadCepCreateBrokerRequest, _loadCepCreateBrokerRequest),
  on(loadCepCreateBrokerSuccess, _loadCepCreateBrokerSuccess),
  on(loadBrokerByCpfRequest, _loadBrokerByCpfRequest),
  on(loadBrokerByCpfSuccess, _loadBrokerByCpfSuccess),
  on(clearBrokerProtheus, _clearBrokerProtheus)
);
