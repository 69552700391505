import { Pipe, PipeTransform } from '@angular/core';
import { CadastralMovementImportStatus } from 'src/app/features/sale/enums/CadastralMovementImportStatus';

@Pipe({
  name: 'importType',
})
export class ImportTypePipe implements PipeTransform {
  transform(type: CadastralMovementImportStatus): string {
    const labels = {
      0: 'Inclusão',
      1: 'Alteração',
      2: 'Exclusão',
      3: 'Posição Cadastral',
    };
    return labels[type];
  }
}
