import { createAction, props } from '@ngrx/store';
import { Prop } from 'src/app/core/models/Prop';
import { AgeRange } from 'src/app/models/AgeRange';
import { Client } from 'src/app/models/Client';
import { Company } from 'src/app/models/Company';
import { CurrentOperator } from 'src/app/models/CurrentOperator';
import { Plan } from 'src/app/models/Plan';
import { Preferences } from 'src/app/models/Preferences';
import { Simulation } from 'src/app/models/Simulation';
import { RecoverSimulationPayload } from 'src/app/models/RecoverSimulationPayload';
import { SendSimulationRequestPayload } from '../interfaces/SendSimulationRequestPayload';

enum SimulationActions {
  InitCreateSimulation = '[Simulation] Init Create Simulation',
  CreateSimulation = '[Simulation] Create Simulation',

  SendSimulationRequest = '[Simulation] Send Simulation Request',
  SendSimulationError = '[Simulation] Send Simulation Error',
  SendSimulationSuccess = '[Simulation] Send Simulation Success',

  ShowSignInModal = '[Simulation] Show Sign In Modal',
  HideSignInModal = '[Simulation] Hide Sign In Modal',

  ShowSignInModalOrInitSale = '[Simulation] Show Sign In Modal Or Init Plan Sale',

  LoadSimulationsRequest = '[Simulation] Load Simulations Request',
  LoadSimulationsSuccess = '[Simulation] Load Simulations Success',
  LoadSimulationsError = '[Simulation] Load Simulations Error',

  ResendPdfSimulationRequest = '[Simulation] Resend PDF Simulation Request',
  ResendPdfSimulationSuccess = '[Simulation] Resend PDF Simulation Success',
  ResendPdfSimulationError = '[Simulation] Resend PDF Simulation Error',

  LoadTotalSimulationsRequest = '[Simulation] Load Total Simulations Request',
  LoadTotalSimulationsSuccess = '[Simulation] Load Total Simulations Success',
  LoadTotalSimulationsError = '[Simulation] Load Total Simulations Error',

  RecoverSimulationRequest = '[Simulation] Recover Simulation Request',
  RecoverSimulationSuccess = '[Simulation] Recover Simulation Success',

  DeleteSimulationRequest = '[Simulation] Delete Simulation Request',
  DeleteSimulationSuccess = '[Simulation] Delete Simulation Success',
  DeleteSimulationError = '[Simulation] Delete Simulation Error',

  ChangePlanRange = '[Simulation] Change Plan Range',
  ClearRanges = '[Simulation] Clear Ranges',
  ClearSimilarPlans = '[Simulation] Clear Similar Plans',
  DeleteSimilarPlanChanged = '[Simulation] Delete Similar Plan Changed',
  SetEntityCode = '[Simulation] Set Entity Code',
  SetPreferences = '[Simulation] Set Preferences',
  SetRanges = '[Simulation] Set Ranges',
  SetSimilarPlans = '[Simulation] Set Similar Plans',
  SetSimilarPlansSelected = '[Simulation] Set Similar Plans Selected',
  SetTargetAudience = '[Simulation] Set Target Audience',
  UpdatePlan = '[Simulation] Update Plan',

  ClearSimulation = '[Simulation] Clear Simulation',

  SetCompany = '[Simulation] Set Company',

  SetLifesAmount = '[Simulation] Set Lifes Amount',

  ClearClientByDocNumber = '[Simulation] Clear Client By Doc Number',
  SetClient = '[Simulation] Set Client',
  SetCurrentOperators = '[Simulation] Set Current Operators',
  SetDocNumber = '[Simulation] Set Doc Number',
  SetShowClientNotFoundMsg = '[Simulation] Set Show Client Not Found Msg',
}

export const createSimulation = createAction(
  SimulationActions.CreateSimulation,
  props<Prop<SendSimulationRequestPayload>>()
);

export const initCreateSimulation = createAction(
  SimulationActions.InitCreateSimulation
);

export const sendSimulationRequest = createAction(
  SimulationActions.SendSimulationRequest,
  props<Prop<Simulation>>()
);

export const sendSimulationError = createAction(
  SimulationActions.SendSimulationError
);

export const sendSimulationSuccess = createAction(
  SimulationActions.SendSimulationSuccess,
  props<Prop<{ sentSimulation: Simulation; simulationId: number }>>()
);

export const showSignInModal = createAction(SimulationActions.ShowSignInModal);

export const hideSignInModal = createAction(SimulationActions.HideSignInModal);

export const showSignInModalOrInitSale = createAction(
  SimulationActions.ShowSignInModalOrInitSale
);

export const loadSimulationsRequest = createAction(
  SimulationActions.LoadSimulationsRequest,
  props<Prop<{ pageSize: number; pageIndex: number }>>()
);

export const loadSimulationsSuccess = createAction(
  SimulationActions.LoadSimulationsSuccess,
  props<Prop<Simulation[]>>()
);

export const loadSimulationsError = createAction(
  SimulationActions.LoadSimulationsError
);

export const deleteSimulationRequest = createAction(
  SimulationActions.DeleteSimulationRequest,
  props<Prop<number>>()
);

export const deleteSimulationSuccess = createAction(
  SimulationActions.DeleteSimulationSuccess,
  props<Prop<number>>()
);

export const deleteSimulationError = createAction(
  SimulationActions.DeleteSimulationError
);

export const resendPdfSimulationRequest = createAction(
  SimulationActions.ResendPdfSimulationRequest,
  props<Prop<{ simulationId: number; emailTo: string }>>()
);

export const resendPdfSimulationSuccess = createAction(
  SimulationActions.ResendPdfSimulationSuccess
);

export const resendPdfSimulationError = createAction(
  SimulationActions.ResendPdfSimulationError
);

export const loadTotalSimulationsRequest = createAction(
  SimulationActions.LoadTotalSimulationsRequest
);

export const loadTotalSimulationsSuccess = createAction(
  SimulationActions.LoadTotalSimulationsSuccess,
  props<Prop<number>>()
);

export const loadTotalSimulationsError = createAction(
  SimulationActions.LoadTotalSimulationsError
);

export const recoverSimulationRequest = createAction(
  SimulationActions.RecoverSimulationRequest,
  props<Prop<Simulation>>()
);

export const recoverSimulationSuccess = createAction(
  SimulationActions.RecoverSimulationSuccess,
  props<Prop<RecoverSimulationPayload>>()
);

export const clearSimulation = createAction(SimulationActions.ClearSimulation);

export const setCompany = createAction(
  SimulationActions.SetCompany,
  props<Prop<Company>>()
);

export const setLifesAmount = createAction(
  SimulationActions.SetLifesAmount,
  props<Prop<number>>()
);

export const setPreferences = createAction(
  SimulationActions.SetPreferences,
  props<Prop<Preferences>>()
);

export const setRanges = createAction(
  SimulationActions.SetRanges,
  props<Prop<AgeRange[]>>()
);

export const clearRanges = createAction(SimulationActions.ClearRanges);

export const changePlanRange = createAction(
  SimulationActions.ChangePlanRange,
  props<Prop<Plan>>()
);

export const deleteSimilarPlanChanged = createAction(
  SimulationActions.DeleteSimilarPlanChanged,
  props<Prop<Plan>>()
);

export const updatePlan = createAction(
  SimulationActions.UpdatePlan,
  props<Prop<Plan>>()
);

export const setSimilarPlans = createAction(
  SimulationActions.SetSimilarPlans,
  props<Prop<Plan[]>>()
);

export const clearSimilarPlans = createAction(
  SimulationActions.ClearSimilarPlans
);

export const setSimilarPlansSelected = createAction(
  SimulationActions.SetSimilarPlansSelected,
  props<Prop<Plan[]>>()
);

export const setTargetAudience = createAction(
  SimulationActions.SetTargetAudience,
  props<Prop<string>>()
);

export const setEntityCode = createAction(
  SimulationActions.SetEntityCode,
  props<Prop<string>>()
);

export const clearClient = createAction(
  SimulationActions.ClearClientByDocNumber
);

export const setClient = createAction(
  SimulationActions.SetClient,
  props<Prop<Client | undefined>>()
);

export const setCurrentOperators = createAction(
  SimulationActions.SetCurrentOperators,
  props<Prop<CurrentOperator[]>>()
);

export const setDocNumber = createAction(
  SimulationActions.SetDocNumber,
  props<Prop<string>>()
);

export const setShowClientNotFoundMsg = createAction(
  SimulationActions.SetShowClientNotFoundMsg,
  props<Prop<boolean>>()
);
