import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
})
export class InputPhoneComponent extends CustomInput implements OnInit {
  @Input() label = 'Telefone';
  @Input() icon!: string;
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  override errorList = ['mask'];
  mask!: string;

  constructor(
    @Self() @Optional() protected override ngControl: NgControl,
    private cd: ChangeDetectorRef
  ) {
    super(ngControl);
  }

  override ngOnInit(): void {
    this.registerChanges();
  }

  getMask(): string {
    const { length } = String(this.control.value);
    const phoneMask = '0000-00009';
    const cellphoneMask = '0 0000-00009';
    const phoneMaskWithDDD = '(00) 0000-00009';
    const cellphoneMaskWithDDD = '(00) 0 0000-0000';

    if (length < 9) {
      return phoneMask;
    }

    if (length < 10) {
      return cellphoneMask;
    }

    if (length < 11) {
      return phoneMaskWithDDD;
    }

    return cellphoneMaskWithDDD;
  }

  setMask(): void {
    this.mask = this.getMask();
    this.cd.detectChanges();
  }
}
