<mat-form-field appearance="outline" class="w-100" (click)="validateField()">
  <mat-label>Selecione uma Corretora</mat-label>
  <mat-select placeholder="Selecione a Corretora" [formControl]="control">
    <mat-option *ngFor="let bro of brokers" [value]="bro.id">
      {{ bro.description }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="control.hasError('required')">
    Campo obrigatório
  </mat-error>
</mat-form-field>
