import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Provider } from 'src/app/models/Provider';
import { ProviderService } from 'src/app/core/services/provider';

import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-select-provider',
  templateUrl: './select-provider.component.html',
  styleUrls: ['./select-provider.component.scss'],
})
export class SelectProviderComponent extends CustomInput implements OnInit {
  @Input() label = 'Operadora';
  @Input() multiple = false;

  providers!: Provider[];

  constructor(
    @Self() @Optional() ngControl: NgControl,
    private providerService: ProviderService
  ) {
    super(ngControl);
  }

  override ngOnInit(): void {
    this.getProviders();
    this.registerChanges();
  }

  getProviders(): void {
    this.providerService.getAllProviders().subscribe((providers) => {
      this.providers = providers;
    });
  }
}
