import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-input-money',
  templateUrl: './input-money.component.html',
  styleUrls: ['./input-money.component.scss'],
})
export class InputMoneyComponent extends CustomInput {
  @Input() label = 'valor';
  @Input() icon!: string;

  override control = new UntypedFormControl();
  override errorList = [];
}
