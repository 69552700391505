import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  clearSimulationStep,
  loadEnterprise,
  toggleCartPopup,
} from 'src/app/core/store/core/core.actions';
import { AppState } from 'src/app/core/store/core/core.interfaces';
import {
  selectCart,
  selectEnterprise,
  selectIsMobileScreen,
  selectShoppingCartPlansAmount,
  selectShouldShowShoppingCartButton,
  selectShowHeader,
  selectShowMenuList,
  selectSystemSegment,
} from 'src/app/core/store/core/core.selectors';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import {
  selectAccessType,
  selectDecodedToken,
  selectIsLoggedIn,
} from 'src/app/core/store/auth/auth.selectors';
import { logout } from 'src/app/core/store/auth/auth.actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  showHeader$ = this.store.select(selectShowHeader);
  shouldShowMenuList$ = this.store.select(selectShowMenuList);
  isLoggedIn$ = this.store.select(selectIsLoggedIn);
  enterprise$ = this.store.select(selectEnterprise);
  isMobileScreen$ = this.store.select(selectIsMobileScreen);
  shouldShowShoppingCartButton$ = this.store.select(
    selectShouldShowShoppingCartButton
  );

  decodedToken$ = this.store.select(selectDecodedToken);

  cart$ = this.store.select(selectCart);

  shoppingCartPlansAmount$ = this.store.select(selectShoppingCartPlansAmount);

  systemSegment$ = this.store.select(selectSystemSegment);

  accessType$ = this.store.select(selectAccessType);

  isLocalEnvironment = location.href.includes('http://localhost');

  faSignIn = faSignIn as IconProp;

  constructor(private router: Router, private store: Store<AppState>) {}

  get shouldConfirm(): boolean {
    return ['pme', 'adesao', 'pf'].some((u) => `/${u}` === this.router.url);
  }

  showCartPopup(): void {
    this.store.dispatch(toggleCartPopup());
  }

  confirmGoBack(): void {
    if (!this.shouldConfirm) {
      this.goBack();
      return;
    }
  }

  refreshEnterprise(): void {
    this.store.dispatch(loadEnterprise());
  }

  isMySimulationsPage(): boolean {
    return location.pathname.includes('/simulacoes');
  }

  onLogoutClick(): void {
    this.store.dispatch(logout());
  }

  clearSimulationStep(): void {
    this.store.dispatch(clearSimulationStep());
  }

  private goBack(): void {
    this.router.navigate(['inicio']);
  }
}
