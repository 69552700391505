<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="text"
    inputmode="numeric"
    autocomplete="new-password"
    [formControl]="control"
    (blur)="validateField()"
    [mask]="mask"
    (keyup)="setMask()"
  />
  <mat-icon *ngIf="icon || (control.errors && wasValidated)" matSuffix>
    {{ control.errors ? "close" : icon }}
  </mat-icon>
  <mat-icon
    *ngIf="icon || (control.value && !control.errors && wasValidated)"
    matSuffix
  >
    done
  </mat-icon>
  <mat-error *ngIf="control.hasError('required')">
    Campo obrigatório
  </mat-error>
  <mat-error *ngIf="control.hasError('mask')">
    Digite o telefone corretamente
  </mat-error>
</mat-form-field>
