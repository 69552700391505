import { Component, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { CustomInput } from '../CustomInput';
import { Store } from '@ngrx/store';
import { selectCities } from 'src/app/core/store/inputs/inputs.selectors';
import { map, merge, Observable, switchMap, take } from 'rxjs';
import { City } from 'src/app/models/City';
import { normalize } from 'src/app/core/utils/normalize';
@Component({
  selector: 'app-select-city',
  templateUrl: './select-city.component.html',
  styleUrls: ['./select-city.component.scss'],
})
export class SelectCityComponent extends CustomInput {
  cities$ = this.store.select(selectCities);

  filteredCities$ = merge(this.control.valueChanges, this.cities$).pipe(
    map(() => this.control.value || ''),
    switchMap((value) => this.filterCitiesByName(value))
  );

  constructor(private store: Store, @Self() @Optional() ngControl: NgControl) {
    super(ngControl);
  }
  displayFn(city: City): string {
    return city?.nome || '';
  }

  private filterCitiesByName(name: string): Observable<City[]> {
    return this.cities$.pipe(
      take(1),
      map((cities) => cities ?? []),
      map((cities) =>
        cities.filter((city) =>
          normalize(city.nome)
            ?.toLowerCase()
            .includes(normalize(name)?.toLowerCase())
        )
      )
    );
  }
}
