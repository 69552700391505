import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ClientService } from 'src/app/core/services/client';
import { Entity } from 'src/app/models/Entity';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification';
import { selectSimulationStep } from 'src/app/core/store/core/core.selectors';
import { selectLifesAmount } from 'src/app/features/simulation/store/simulation.selectors';
import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-select-entity',
  templateUrl: './select-entity.component.html',
  styleUrls: ['./select-entity.component.scss'],
})
export class SelectEntityComponent
  extends CustomInput
  implements OnInit, OnDestroy
{
  @Input() label = 'Entidade';

  entities: Entity[] = [];

  destroyed$ = new Subject<void>();

  lifesAmount?: number;

  simulationStep?: number;

  constructor(
    @Self() @Optional() ngControl: NgControl,
    private store: Store,
    private clientService: ClientService,
    private notification: NotificationService
  ) {
    super(ngControl);
  }

  @Input() set targetAudience(_targetAudience: string) {
    if (!_targetAudience) {
      return;
    }
    this.getEntities(_targetAudience);
  }

  override ngOnInit(): void {
    this.registerChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getLifesAmount(): void {
    this.store
      .select(selectLifesAmount)
      .pipe(takeUntil(this.destroyed$), filter(Boolean))
      .subscribe((lifesAmount) => {
        this.lifesAmount = lifesAmount;
      });
  }

  private setSimulationStep(): void {
    this.store
      .select(selectSimulationStep)
      .pipe(take(1))
      .subscribe((step) => {
        this.simulationStep = step;
      });
  }

  private getEntities(id: string): void {
    this.getLifesAmount();
    this.setSimulationStep();

    // const isPreferenceStep = this.simulationStep === 2;

    // if (isPreferenceStep) {
    //   this.loader.show('Carregando entidades...');
    // }

    this.clientService
      .getEntities(id, this.lifesAmount!)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((entities) => {
        let chavesVistas = new Set();
        let entidades = entities.filter((obj) => {
          if (chavesVistas.has(obj.codeEntity)) {
            return false;
          } else {
            chavesVistas.add(obj.codeEntity);
            return true;
          }
        });
        this.entities = entidades;

        if (!entities.length) {
          this.notification.info('Nenhuma entidade encontrada.');
        }
      });
    // .add(() => {
    //   if (isPreferenceStep) {
    //     this.loader.hide();
    //   }
    // });
  }
}
