import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/core/core.interfaces';
import { clearAuthState } from '../../store/auth/auth.actions';

export const SHOULD_SKIP_ERROR_HANDLER = new HttpContextToken(() => false);

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    private notification: NotificationService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldSkipErrorHandler(request)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(catchError((e) => this.handleError(e)));
  }

  private handleError(e: HttpErrorResponse): Observable<never> {
    const errors = this.getErrorMessages(e);

    if (errors) {
      errors.forEach((err) => this.notification.info(err));
    }

    this.redirectIf401(e);

    return throwError(() => new Error(e as never));
  }

  private getErrorMessages(e: HttpErrorResponse): string[] | null {
    if (e.status >= 500) {
      return [
        'Houve um erro interno do servidor. Se o erro persistir, contate o desenvolvedor.',
      ];
    }

    if (e.status === 403) {
      return ['Acesso negado.'];
    }

    return e.error;
  }

  private redirectIf401(e: HttpErrorResponse): void {
    const statusCode = e.status;

    if (statusCode === 401) {
      this.goToLogin();
    }
  }

  private goToLogin(): void {
    this.store.dispatch(clearAuthState());
    this.notification.info('O acesso foi expirado.');
    this.router.navigate(['autenticacao', 'entrar']);
  }

  private shouldSkipErrorHandler(request: HttpRequest<unknown>): boolean {
    return request.context.get(SHOULD_SKIP_ERROR_HANDLER);
  }
}
