import { createAction, props } from '@ngrx/store';
import { Prop } from 'src/app/core/models/Prop';

enum SaleActions {
  ClearSaleState = '[Sale] Clear Sale State',

  InitSaleRequest = '[Sale] Init Sale Request',
  InitSaleSuccess = '[Sale] Init Sale Success',
  InitSaleError = '[Sale] Init Sale Error',
}

export const clearSaleState = createAction(SaleActions.ClearSaleState);

export const initSaleRequest = createAction(SaleActions.InitSaleRequest);

export const initSaleSuccess = createAction(
  SaleActions.InitSaleSuccess,
  props<Prop<string>>()
);

export const initSaleError = createAction(SaleActions.InitSaleError);
