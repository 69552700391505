import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentTypeFormat',
})
export class PaymentTypeFormatPipe implements PipeTransform {
  transform(value: number, paymentType: number): string {
    if (paymentType === 0) {
      return '-';
    }

    if (paymentType === 1) {
      return `${value} %`;
    }

    return `${value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })}`;
  }
}
