<div class="auth-container">
  <mat-card class="m-4 shadow">
    <h1 class="auth-title">{{ title }}</h1>
    <p class="text-muted mb-3">Simulador de planos</p>

    <small
      class="text-default mt-4"
      *ngIf="accessType === 1 && !brokerProtheus"
    >
      Informe o CPF do(a) corretor(a)
    </small>

    <app-create-user-form
      *ngIf="accessType === 2"
      (successfully)="redirectSuccess()"
    ></app-create-user-form>

    <app-create-broker-user-form
      [accessType]="accessType"
      (successfully)="redirectSuccess()"
    ></app-create-broker-user-form>
  </mat-card>
</div>
