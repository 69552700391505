import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { cookieAccepted } from 'src/app/core/store/core/core.actions';
import {
  selectEnterprise,
  selectShowCookieAcceptance,
} from 'src/app/core/store/core/core.selectors';
import { AppState } from 'src/app/core/store/core/core.interfaces';

@Component({
  selector: 'app-cookie-acceptance',
  templateUrl: './cookie-acceptance.component.html',
  styleUrls: ['./cookie-acceptance.component.scss'],
})
export class CookieAcceptanceComponent {
  enterprise$ = this.store.select(selectEnterprise);
  showCookieAcceptance$ = this.store.select(selectShowCookieAcceptance);

  constructor(private store: Store<AppState>) {}

  onAgreeClick(): void {
    this.store.dispatch(cookieAccepted());
  }
}
