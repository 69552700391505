import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, Subject, switchMap, take } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification';
import { selectSystemSegment } from 'src/app/core/store/core/core.selectors';
import { ProtheusService } from 'src/app/features/sale/services/protheus';
import { HealthBrokerCompany } from 'src/app/models/HealthBrokerCompany';
import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-select-broker',
  templateUrl: './select-broker.component.html',
  styleUrls: ['./select-broker.component.scss'],
})
export class SelectBrokerComponent
  extends CustomInput
  implements OnInit, OnDestroy
{
  @Input() label = 'Corretora';

  brokers: HealthBrokerCompany[] = [];
  destroyed$ = new Subject<void>();

  constructor(
    @Self() @Optional() ngControl: NgControl,
    private store: Store,
    private protheusService: ProtheusService,
    private notification: NotificationService
  ) {
    super(ngControl);
  }

  override ngOnInit(): void {
    this.getBrokers();
    this.registerChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getBrokers(): void {
    this.store
      .select(selectSystemSegment)
      .pipe(
        take(1),
        filter(Boolean),
        switchMap((segment) =>
          this.protheusService.getProtheusHealthBrokers(segment)
        )
      )
      .subscribe((brokers) => {
        this.brokers = brokers;

        if (!brokers.length) {
          this.notification.info('Nenhuma corretora encontrada.');
        }
      });

    // this.protheusService.getProtheusHealthBrokers(segment)
    //         .pipe(takeUntil(this.destroyed$))
    //         .subscribe((brokers) => {
    //           this.brokers = brokers;

    //           if (!brokers.length) {
    //             this.notification.info('Nenhuma corretora encontrada.');
    //           }
    //         }).
  }
}
