import { Component, Input } from '@angular/core';

import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-select-yes-or-no',
  templateUrl: './select-yes-or-no.component.html',
  styleUrls: ['./select-yes-or-no.component.scss'],
})
export class SelectYesOrNoComponent extends CustomInput {
  @Input() label!: string;
}
