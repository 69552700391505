import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { DefaultResponse } from 'src/app/core/models/DefaultResponse';
import endpoints from 'src/environments/endpoints';
import { TargetAudience } from 'src/app/models/TargetAudience';
import { Client } from 'src/app/models/Client';
import { Entity } from 'src/app/models/Entity';
import { addIdToPlans } from '../../utils/add-id-to-plans';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  targetAudiences$ = this.getTargetAudiencesObservable();

  constructor(private http: HttpClient) {}

  findClientByDocNumberAndSegment(
    docNumber: string,
    segment: string
  ): Observable<Client> {
    const baseUrl = endpoints.clients;
    const url = `${baseUrl}/documentNumber/${docNumber}/segment/${segment}`;

    return this.http.get<Client>(url).pipe(
      map((client) => ({
        ...client,
        contractedPlan: addIdToPlans(client.contractedPlan),
      }))
    );
  }

  getEntities(id: string, amounOfLives: number): Observable<Entity[]> {
    const baseUrl = endpoints.entities;
    const url = `${baseUrl}/idTargetAudience/${id}/${amounOfLives}`;

    return this.http
      .get<DefaultResponse<Entity[]>>(url)
      .pipe(map((res) => res?.data));
  }

  getTargetAudiences(): Observable<TargetAudience[]> {
    return this.targetAudiences$;
  }

  private getTargetAudiencesObservable(): Observable<TargetAudience[]> {
    const url = endpoints.targetAudiences;

    return this.http.get<DefaultResponse<TargetAudience[]>>(url).pipe(
      map((res) => res?.data),
      share()
    );
  }
}
