import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'proposalSegment',
})
export class ProposalSegmentPipe implements PipeTransform {
  transform(segment: number): string {
    return segment === 0 ? 'PME' : segment === 1 ? 'Adesao' : 'Individual';
  }
}
