import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './components/auth';
import { LoginComponent } from './components/login';
import { LogoutComponent } from './components/logout';
import { ResetPassComponent } from './components/reset-pass';
import { CreateUserComponent } from './components/create-user';
import { RequestPassComponent } from './components/request-pass';

const routes: Routes = [
  {
    path: 'autenticacao',
    pathMatch: 'full',
    redirectTo: 'autenticacao/entrar',
  },

  {
    path: 'autenticacao',
    component: AuthComponent,
    children: [
      { path: 'entrar', component: LoginComponent },
      { path: 'sair', component: LogoutComponent },
      { path: 'restaurar-senha', component: ResetPassComponent },
      { path: 'esqueci-minha-senha', component: RequestPassComponent },
      { path: 'cadastrar', component: CreateUserComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
