import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import endpoints from 'src/environments/endpoints';
import { SHOULD_ADD_TENANT_WITHOUT_BRANCH } from '../../interceptors/auth';
import { DefaultResponse } from '../../models/DefaultResponse';
import { AddPlanToShoppingCartPayload } from '../../models/shopping-cart/AddPlanToShoppingCartPayload';
import { ShoppingCart } from '../../models/shopping-cart/ShoppingCart';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  shoppingCart?: ShoppingCart;

  private baseURL = endpoints.shoppingCart;

  private context = new HttpContext().set(
    SHOULD_ADD_TENANT_WITHOUT_BRANCH,
    true
  );

  constructor(private http: HttpClient) {}

  getShoppingCart(): Observable<ShoppingCart> {
    return this.http
      .get<DefaultResponse<ShoppingCart[]>>(this.baseURL, {
        context: this.context,
      })
      .pipe(map((res) => res?.data[0]));
  }

  addPlan(payload: AddPlanToShoppingCartPayload): Observable<void> {
    return this.http.post<void>(this.baseURL, payload, {
      context: this.context,
    });
  }

  deletePlan(shoppingCartPlanId: string): Observable<void> {
    const baseUrl = endpoints.shoppingCartItemDetail;
    const url = `${baseUrl}/${shoppingCartPlanId}`;

    return this.http.delete<void>(url, {
      context: this.context,
    });
  }

  clearCart(): Observable<void> {
    return this.http.delete<void>(this.baseURL, {
      context: this.context,
    });
  }

  getCartByGuidProposal(guidProposal: string): Observable<ShoppingCart> {
    const url = `${this.baseURL}/${guidProposal}`;
    return this.http
      .get<DefaultResponse<ShoppingCart>>(url, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  listShoppingCarts(
    pageIndex: number,
    pageSize: number
  ): Observable<ShoppingCart[]> {
    const url = `${this.baseURL}/list/pageIndex/${pageIndex}/pageSize/${pageSize}`;

    return this.http
      .get<DefaultResponse<ShoppingCart[]>>(url, {
        context: this.context,
      })
      .pipe(map((res) => res?.data));
  }

  deleteCart(cartId: string): Observable<void> {
    const url = `${this.baseURL}/${cartId}`;

    return this.http.delete<void>(url, {
      context: this.context,
    });
  }
}
