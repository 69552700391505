<ng-container *ngIf="linkTo">
  <a
    [routerLink]="linkTo"
    mat-raised-button
    color="accent"
    *ngIf="color === 'primary'"
    [class.w-100]="fullWidth"
    [class.button-disabled]="disabled"
  >
    {{ label }}
  </a>
  <a
    [routerLink]="linkTo"
    mat-stroked-button
    color="primary"
    *ngIf="color === 'secondary'"
    [class.w-100]="fullWidth"
    [class.button-disabled]="disabled"
  >
    {{ label }}
  </a>
</ng-container>

<ng-container *ngIf="!linkTo">
  <button
    mat-raised-button
    color="accent"
    *ngIf="color === 'primary'"
    [class.w-100]="fullWidth"
    [class.button-disabled]="disabled"
    [type]="type"
    [disabled]="disabled"
  >
    {{ label }}
  </button>
  <button
    mat-stroked-button
    color="primary"
    *ngIf="color === 'secondary'"
    [class.w-100]="fullWidth"
    [class.button-disabled]="disabled"
    [type]="type"
    [disabled]="disabled"
  >
    {{ label }}
  </button>
</ng-container>
