import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CoreState } from './core.interfaces';
import packageJsonInfo from '../../../../../package.json';
import { convertSegmentToBranchCode } from '../../converters/ConvertSegmentToBranchCode';
import { AuthState } from '../auth/auth.interfaces';

const selectFeature = createFeatureSelector<CoreState>('core');

const selectAuth = createFeatureSelector<AuthState>('auth');

export const selectSystemSegment = createSelector(
  selectFeature,
  (state) => state.systemSegment
);

export const selectIsPME = createSelector(
  selectFeature,
  (state) => state.systemSegment === 'PME'
);

export const selectIsCookieAccepted = createSelector(
  selectFeature,
  (state) => state.isCookieAccepted
);

export const selectEnterprise = createSelector(
  selectFeature,
  (state) => state.enterprise
);

export const selectSimulationStep = createSelector(
  selectFeature,
  (state) => state.simulationStep
);

export const selectIsAppVersionUpdated = createSelector(
  selectFeature,
  (state) => state.appVersion === packageJsonInfo.version
);

export const selectHasEnterprise = createSelector(
  selectFeature,
  (state) => !!state.enterprise
);

export const selectShowHeader = createSelector(
  selectFeature,
  (state) => state.showHeader
);

export const selectShowMenuList = createSelector(
  selectFeature,
  (state) => state.showMenuList
);

export const selectIsSaleFlow = createSelector(
  selectFeature,
  (state) => state.isSaleFlow
);

export const selectTenantIdWithoutBranch = createSelector(
  selectFeature,
  (state) => state.enterprise?.tenantProtheus
);

export const selectTenantIdWithBranch = createSelector(
  selectFeature,
  (state) => {
    const branch = convertSegmentToBranchCode(state.systemSegment);
    const tenantProtheus = state.enterprise?.tenantProtheus;

    return branch ? `${tenantProtheus},${branch}` : `${tenantProtheus}`;
  }
);

export const selectScreenWidth = createSelector(
  selectFeature,
  (state) => state.screenWidth
);

export const selectStepperOrientation = createSelector(selectFeature, (state) =>
  state.screenWidth > 560 ? 'horizontal' : 'vertical'
);

export const selectIsMobileScreen = createSelector(
  selectFeature,
  (state) => state.screenWidth < 768
);

export const selectIsSmallScreen = createSelector(
  selectFeature,
  (state) => state.screenWidth < 1000
);

export const selectCore = createSelector(selectFeature, (state) => state);

export const selectShowCookieAcceptance = createSelector(
  selectFeature,
  (state) => !state.isCookieAccepted
);

export const selectCart = createSelector(selectCore, (state) => state.cart);

export const selectTotalCartValue = createSelector(selectCore, (state) =>
  state.cart?.shoppingCartItems.reduce(
    (acc, item) =>
      acc +
      item.shoppingCartItemDetails.reduce(
        (acc2, detail) => acc2 + detail.value,
        0
      ),
    0
  )
);

export const selectIsEmptyCart = createSelector(
  selectFeature,
  (state) => !state.cart?.shoppingCartItems?.length
);

export const selectShoppingCartItems = createSelector(
  selectFeature,
  (state) => state.cart?.shoppingCartItems.length
);

export const selectShoppingCartPlansAmount = createSelector(
  selectFeature,
  (state) =>
    state.cart?.shoppingCartItems.reduce(
      (acc, planGroup) => acc + planGroup.shoppingCartItemDetails.length,
      0
    ) || 0
);

export const selectShowCartPopup = createSelector(
  selectFeature,
  (state) => state.showCartPopup
);

export const selectShouldShowShoppingCartButton = createSelector(
  selectFeature,
  selectAuth,
  (core, auth) => core.simulationStep === 3 && !!auth.accessToken
);

export const selectIsLoadingCart = createSelector(
  selectFeature,
  (state) => state.isLoadingCart
);
