import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomInput } from './CustomInput';
import { InputAgeRangeComponent } from './input-age-range';
import { InputCnpjComponent } from './input-cnpj';
import { InputCpfComponent } from './input-cpf';
import { InputDateComponent } from './input-date/input-date.component';
import { InputEmailComponent } from './input-email';
import { InputFieldComponent } from './input-field';
import { InputMoneyComponent } from './input-money';
import { InputPhoneComponent } from './input-phone';
import { InputSlideToggleComponent } from './input-slide-toggle';
import { SelectAssistanceSegmentComponent } from './select-assistance-segment';
import { SelectBrokerComponent } from './select-broker/select-broker.component';
import { SelectCityComponent } from './select-city';
import { SelectCoverageComponent } from './select-coverage';
import { SelectEntityComponent } from './select-entity';
import { SelectProviderComponent } from './select-provider';
import { SelectStateComponent } from './select-state';
import { SelectTargetAudienceComponent } from './select-target-audience';
import { SelectYesOrNoComponent } from './select-yes-or-no';

const COMPONENTS = [
  CustomInput,

  InputCpfComponent,
  InputCnpjComponent,
  InputDateComponent,
  InputEmailComponent,
  InputPhoneComponent,
  InputMoneyComponent,
  InputFieldComponent,
  InputAgeRangeComponent,
  InputSlideToggleComponent,
  SelectBrokerComponent,
  SelectCityComponent,
  SelectStateComponent,
  SelectEntityComponent,
  SelectYesOrNoComponent,
  SelectProviderComponent,
  SelectCoverageComponent,
  SelectTargetAudienceComponent,
  SelectAssistanceSegmentComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,

    NgxMaskModule.forRoot(),
    CurrencyMaskModule,

    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
  ],

  exports: [...COMPONENTS],
})
export class InputsModule {}
