import { createReducer, on } from '@ngrx/store';

import { CoreState } from './core.interfaces';

import {
  setSystemSegment,
  cookieAcceptanceSuccess,
  setAppVersion,
  setShowHeader,
  setEnterprise,
  showCartPopup,
  hideCartPopup,
  setIsSaleFlow,
  setScreenWidth,
  setSimulationStep,
  clearCoreState,
  toggleCartPopup,
  setShoppingCart,
  loadCartRequest,
  setShowMenuList,
  initSaleRequest,
  clearSimulationStep,
} from './core.actions';
import { SystemSegment } from 'src/app/models/SystemSegment';
import packageJsonInfo from '../../../../../package.json';
import { Enterprise } from 'src/app/models/Enterprise';
import { Prop } from '../../models/Prop';
import { ShoppingCart } from '../../models/shopping-cart/ShoppingCart';

const initialState: CoreState = {
  isCookieAccepted: false,
  appVersion: packageJsonInfo.version,
  showHeader: true,
  showMenuList: true,
  showCartPopup: false,
  isSaleFlow: false,
  screenWidth: window.screen.width,
  cart: undefined,
  isLoadingCart: false,
};

const _setEnterprise = (
  state: CoreState,
  action: Prop<Enterprise>
): CoreState => ({
  ...state,
  enterprise: action.payload,
});

const _setSystemSegment = (
  state: CoreState,
  action: Prop<SystemSegment>
): CoreState => ({
  ...state,
  systemSegment: action.payload,
});

const _clearCoreState = (state: CoreState): CoreState => ({
  isCookieAccepted: state.isCookieAccepted,
  enterprise: state.enterprise,
  appVersion: state.appVersion,
  showHeader: state.showHeader,
  showMenuList: state.showMenuList,
  showCartPopup: false,
  isSaleFlow: false,
  screenWidth: state.screenWidth,
  cart: undefined,
  isLoadingCart: false,
});

const _cookieAcceptanceSuccess = (state: CoreState): CoreState => ({
  ...state,
  isCookieAccepted: true,
});

const _setAppVersion = (state: CoreState, action: Prop<string>): CoreState => ({
  ...state,
  appVersion: action.payload,
});

const _setShowHeader = (
  state: CoreState,
  action: Prop<boolean>
): CoreState => ({
  ...state,
  showHeader: action.payload,
});

const _setShowMenuList = (
  state: CoreState,
  action: Prop<boolean>
): CoreState => ({
  ...state,
  showMenuList: action.payload,
});

const _toggleCartPopup = (state: CoreState): CoreState => ({
  ...state,
  showCartPopup: !state.showCartPopup,
});

const _showCartPopup = (state: CoreState): CoreState => ({
  ...state,
  showCartPopup: true,
});
const _hideCartPopup = (state: CoreState): CoreState => ({
  ...state,
  showCartPopup: false,
});

const _setShoppingCart = (
  state: CoreState,
  action: Prop<ShoppingCart | undefined>
): CoreState => ({
  ...state,
  cart: action.payload,
  isLoadingCart: false,
});

const _setIsSaleFlow = (
  state: CoreState,
  action: Prop<boolean>
): CoreState => ({
  ...state,
  isSaleFlow: action.payload,
});

const _setScreenWidth = (
  state: CoreState,
  action: Prop<number>
): CoreState => ({
  ...state,
  screenWidth: action.payload,
});

const _setSimulationStep = (
  state: CoreState,
  action: Prop<number>
): CoreState => ({
  ...state,
  simulationStep: action.payload,
});

const _clearSimulationStep = (state: CoreState): CoreState => ({
  ...state,
  simulationStep: undefined,
});

const _loadCartRequest = (state: CoreState): CoreState => ({
  ...state,
  isLoadingCart: true,
});

const _initSaleRequest = (state: CoreState): CoreState => ({
  ...state,
  simulationStep: undefined,
});

export const coreReducer = createReducer(
  initialState,

  on(clearCoreState, _clearCoreState),
  on(cookieAcceptanceSuccess, _cookieAcceptanceSuccess),
  on(setEnterprise, _setEnterprise),
  on(setSystemSegment, _setSystemSegment),
  on(setAppVersion, _setAppVersion),
  on(setShowHeader, _setShowHeader),
  on(setShowMenuList, _setShowMenuList),
  on(toggleCartPopup, _toggleCartPopup),
  on(showCartPopup, _showCartPopup),
  on(hideCartPopup, _hideCartPopup),
  on(setShoppingCart, _setShoppingCart),
  on(setIsSaleFlow, _setIsSaleFlow),
  on(setScreenWidth, _setScreenWidth),
  on(setSimulationStep, _setSimulationStep),
  on(clearSimulationStep, _clearSimulationStep),
  on(loadCartRequest, _loadCartRequest),
  on(initSaleRequest, _initSaleRequest)
);
