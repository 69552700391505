import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards';

import { HomeComponent } from './features/home';
import { NotFoundComponent } from './features/not-found';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'inicio',
  },

  {
    path: 'inicio',
    component: HomeComponent,
  },

  {
    path: 'simulacao',
    loadChildren: () =>
      import('./features/simulation/simulation.module').then(
        (m) => m.SimulationModule
      ),
  },

  {
    path: 'politicas',
    loadChildren: () =>
      import('./features/politicas/politicas.module').then(
        (m) => m.PoliticasModule
      ),
  },

  {
    path: 'autenticacao',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'pagina-nao-encontrada',
    component: NotFoundComponent,
  },

  {
    path: 'venda',
    loadChildren: () =>
      import('./features/sale/sale.module').then((m) => m.SaleModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'assinatura',
    loadChildren: () =>
      import('./features/proposal-signature/proposal-signature.module').then(
        (m) => m.ProposalSignatureModule
      ),
  },

  {
    path: 'portal-do-corretor',
    loadChildren: () =>
      import('./features/broker-area/broker-area.module').then(
        (m) => m.BrokerAreaModule
      ),
    // canActivate: [AuthGuard],
  },

  {
    path: '**',
    redirectTo: 'pagina-nao-encontrada',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
