import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() linkTo!: string;
  @Input() label!: string;
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() fullWidth = false;
  @Input() type = 'button';
  @Input() disabled = false;
}
