<mat-form-field appearance="outline" class="w-100" (click)="validateField()">
  <mat-label>Estado</mat-label>
  <mat-select (selectionChange)="changed.emit($event)" [formControl]="control">
    <mat-option>Selecione o Estado</mat-option>
    <mat-option *ngFor="let uf of states$ | async" [value]="uf">{{
      uf.nome
    }}</mat-option>
  </mat-select>
  <mat-error *ngIf="control.hasError('required')">Campo obrigatório</mat-error>
</mat-form-field>
