import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from 'src/app/core/store/core/core.interfaces';
import { clearCoreState } from 'src/app/core/store/core/core.actions';
import { clearInputsState } from 'src/app/core/store/inputs/inputs.action';
import { clearSaleState } from '../sale/store/sale.actions';
import { clearSimulation } from '../simulation/store/simulation.actions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.clearStateInfo();
  }

  private clearStateInfo(): void {
    this.store.dispatch(clearCoreState());
    this.store.dispatch(clearInputsState());
    this.store.dispatch(clearSaleState());
    this.store.dispatch(clearSimulation());
  }
}
