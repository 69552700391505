import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentType',
})
export class PaymentTypePipe implements PipeTransform {
  transform(paymentType: number): string {
    if (paymentType === 1) {
      return 'Porcentagem';
    }

    return 'Valor Fixo';
  }
}
