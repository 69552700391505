import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cgc',
})
export class CgcPipe implements PipeTransform {
  transform(value: string): string {
    const type = value.length <= 11 ? 'CPF' : 'CNPJ';

    if (type === 'CNPJ') {
      return this.cnpjMask(value);
    }

    if (type === 'CPF') {
      return this.cpfMask(value);
    }

    return '-';
  }

  private cnpjMask(cnpj: string): string {
    return cnpj.replace(this.cnpjRegex(), '$1.$2.$3/$4-$5');
  }

  private cpfMask(cnpj: string): string {
    return cnpj.replace(this.cpfRegex(), '$1.$2.$3-$4');
  }

  private cnpjRegex(): RegExp {
    return /([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/g;
  }

  private cpfRegex(): RegExp {
    return /([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/g;
  }
}
