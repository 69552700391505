import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgxMaskModule } from 'ngx-mask';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { InputsModule } from './inputs/inputs.module';

import { ModalComponent } from './modal';
import { ButtonComponent } from './button';
import { DialogComponent } from './modal/dialog';
import { LoginFormComponent } from './login-form';
import { LoaderComponent } from './loader/loader.component';
import { CookieAcceptanceComponent } from './cookie-acceptance';
import { CollapsibleFilterComponent } from './collapsible-filter';
import { CardSelectTypePlanComponent } from './card-select-type-plan';

import { InputsEffects } from '../store/inputs/inputs.effects';
import { inputsReducer } from '../store/inputs/inputs.reducers';
import { CapsLockDirective } from '../directives/caps-lock';

const COMPONENTS = [
  ModalComponent,
  ButtonComponent,
  LoaderComponent,
  LoginFormComponent,
  CookieAcceptanceComponent,
  CollapsibleFilterComponent,
  CardSelectTypePlanComponent,

  CapsLockDirective,
];

@NgModule({
  declarations: [...COMPONENTS, DialogComponent],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    InputsModule,

    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatRadioModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    MatStepperModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,

    NgxMaskModule.forRoot(),

    StoreModule.forFeature('inputs', inputsReducer),
    EffectsModule.forFeature([InputsEffects]),
  ],
  exports: [...COMPONENTS, InputsModule],
})
export class ComponentsModule {}
