import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appCapsLock]',
})
export class CapsLockDirective {
  @Output() capsLockOn = new EventEmitter<boolean>();

  @HostListener('window:keydown', ['$event'])
  onCapsLockTurnOn(event: KeyboardEvent) {
    if (event.key === 'CapsLock') {
      this.capsLockOn.emit(event.getModifierState('CapsLock'));
    }
  }
}
