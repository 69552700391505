import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CustomInput
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  @Output() changed = new EventEmitter<any>();
  wasValidated = false;
  control = new UntypedFormControl(null);
  errorList: string[] = [];
  readonly = true;

  constructor(@Self() @Optional() protected ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  get value(): any {
    return this.control.value;
  }

  onChange: (_: any) => void = () => {};
  onTouched: (_: any) => void = () => {};

  ngOnInit(): void {
    this.registerChanges();
    this.disableInputAutocomplete();
  }

  ngAfterViewInit(): void {
    this.registerValidators();
    this.registerErrors();
  }

  registerChanges(): void {
    this.control.valueChanges.subscribe((value: any) => {
      this.onChange(value);
    });
  }

  registerErrors(): void {
    this.ngControl?.control?.statusChanges?.subscribe(() => {
      this.replaceErrors();
    });
  }

  replaceErrors(): void {
    if (this.ngControl?.control) {
      const mergedErrors = {
        ...this.ngControl.control.errors,
        ...this.control.errors,
      };

      const localErrors = Object.keys(mergedErrors)
        .filter((e) => this.errorList.includes(e))
        .reduce((a, e) => ({ ...a, [e]: mergedErrors[e] }), {});

      const formattedErrors = {
        ...this.ngControl.control.errors,
        ...localErrors,
      };

      const errorsLength = Object.keys(formattedErrors || {}).length;

      if (errorsLength !== 0) {
        this.control.setErrors(formattedErrors);
      } else {
        this.control.setErrors(null);
      }
    }
  }

  registerValidators(): void {
    if (this.ngControl?.control) {
      const { validator } = this.ngControl.control;
      const controlValidator = this.control.validator;
      const validators = [
        ...this.formatValidator(validator),
        ...this.formatValidator(controlValidator),
      ];

      this.control.setValidators(validators);
      this.ngControl.control.setValidators(validators);
    }
  }

  validateField(): void {
    this.control.updateValueAndValidity();
    this.wasValidated = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  writeValue(newValue: any): void {
    this.control.setValue(newValue);
  }
  /**
   * A variável this.readonly inicia com o valor true para evitar o autocomplete do input
   * Após, um segundo, a função seta o valor do atributo para false, possibilitando o preenchimento do campo
   */
  disableInputAutocomplete(): void {
    setTimeout(() => {
      this.readonly = false;
    }, 1000);
  }

  private formatValidator(validator: any): any[] {
    return (Array.isArray(validator) ? validator : [validator]).filter(Boolean);
  }
}
