import { Pipe, PipeTransform } from '@angular/core';
import { CadastralMovementImportStatus } from 'src/app/features/sale/enums/CadastralMovementImportStatus';

@Pipe({
  name: 'importStatusBadge',
})
export class ImportStatusBadgePipe implements PipeTransform {
  transform(color: CadastralMovementImportStatus): string {
    const colors = {
      0: 'text-danger',
      1: 'text-info',
      2: 'text-primary',
      3: 'text-success',
    };

    return colors[color];
  }
}
