import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/core/store/core/core.interfaces';
import { showNotificationInfo } from 'src/app/core/store/notification/notification.actions';
import { takeUntil } from 'rxjs/operators';
import { resetClientPasswordRequest } from 'src/app/core/store/auth/auth.actions';
import { ResetClientPasswordPayload } from '../../interfaces/ResetClientPasswordPayload';

interface ResetPassForm {
  password: FormControl<string>;
  passwordConfirmation: FormControl<string>;
}

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss'],
})
export class ResetPassComponent implements OnInit, OnDestroy {
  formGroup!: FormGroup<ResetPassForm>;

  destroyed$ = new Subject<void>();

  idUser!: string;
  code!: string;
  showPass = false;

  constructor(
    private fb: NonNullableFormBuilder,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.loadParams();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.store.dispatch(
        showNotificationInfo({ payload: 'Preencha os campos corretamente' })
      );
      return;
    }

    const payload = this.getResetPasswordPayload();

    this.store.dispatch(resetClientPasswordRequest({ payload }));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggleShowPass(): void {
    this.showPass = !this.showPass;
  }

  private loadParams(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ codigo, idUsuario }) => {
        this.code = codigo;
        this.idUser = idUsuario;
      });
  }

  private registerFormGroupChanges(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        Object.values(this.formGroup.controls).forEach((c) => {
          c.updateValueAndValidity({ emitEvent: false });
        });
      });
  }

  private buildForm(): void {
    this.formGroup = this.fb.group({
      password: this.fb.control('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      passwordConfirmation: this.fb.control('', [
        Validators.required,
        Validators.minLength(8),
        this.isEqualTo('password'),
      ]),
    });

    this.registerFormGroupChanges();
  }

  private getResetPasswordPayload(): ResetClientPasswordPayload {
    const password = this.formGroup.get('password')?.value!;
    const confirmPassword = this.formGroup.get('passwordConfirmation')?.value!;
    return {
      idUser: this.idUser,
      code: this.code,
      password,
      confirmPassword,
    };
  }

  private isEqualTo(controlName: string): any {
    return (control: UntypedFormControl) => {
      const targetControlValue = this.formGroup?.get(controlName)?.value;
      const controlValue = control.value;

      if (targetControlValue === controlValue) {
        return null;
      }

      return { different: true };
    };
  }
}
