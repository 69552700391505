<mat-form-field appearance="outline" class="w-100" (click)="validateField()">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="control" [multiple]="true">
    <mat-option value="hospital">Hospitalar</mat-option>
    <mat-option value="outpatient">Ambulatorial</mat-option>
    <mat-option value="obstetrics">Obstetrícia</mat-option>
    <mat-option value="dentistry">Odonto</mat-option>
    <mat-option value="reference">Referência</mat-option>
  </mat-select>
  <mat-error> Campo obrigatório </mat-error>
</mat-form-field>
