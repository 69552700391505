import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  showLoader$ = this.loaderService.showLoader$;
  loaderText$ = this.loaderService.loaderText$;
  destroyed$ = new Subject<void>();

  loaderMessage?: string;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.setLoaderloaderMessage();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setLoaderloaderMessage() {
    this.loaderText$.pipe(takeUntil(this.destroyed$)).subscribe((message) => {
      if (Array.isArray(message)) {
        message.forEach((msg, index) => {
          setTimeout(() => {
            this.loaderMessage = msg;
          }, 3000 * index);
        });
        return;
      }

      this.loaderMessage = message;
    });
  }
}
