<mat-form-field appearance="outline" class="w-100" (click)="validateField()">
  <mat-label> Público-alvo </mat-label>
  <mat-select [formControl]="control">
    <mat-option
      *ngFor="let targetAudience of targetAudiences"
      [value]="targetAudience.code"
    >
      {{ targetAudience.description }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="control.hasError('required')">
    Campo obrigatório
  </mat-error>
</mat-form-field>
