import { Component, Input } from '@angular/core';

import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-input-slide-toggle',
  templateUrl: './input-slide-toggle.component.html',
  styleUrls: ['./input-slide-toggle.component.scss'],
})
export class InputSlideToggleComponent extends CustomInput {
  @Input() label?: string;
}
