import { Pipe, PipeTransform } from '@angular/core';
import { ShoppingCartStatus } from 'src/app/core/models/shopping-cart/ShoppingCartStatus';

@Pipe({
  name: 'proposalStatus',
})
export class ProposalStatusPipe implements PipeTransform {
  transform(status: ShoppingCartStatus): unknown {
    if (status === ShoppingCartStatus.Cancelado) {
      return 'Cancelado';
    }

    if (status === ShoppingCartStatus.Andamento) {
      return 'Em andamento';
    }

    if (status === ShoppingCartStatus.Pendente) {
      return 'Pendente';
    }

    if (status === ShoppingCartStatus.AguardandoAssinatura) {
      return 'Aguardando Assinatura';
    }

    if (status === ShoppingCartStatus.Assinado) {
      return 'Assinado';
    }

    if (status === ShoppingCartStatus.Devolvida) {
      return 'Devolvida';
    }

    return 'Finalizado';
  }
}
