import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthState } from './auth.interfaces';

const selectFeature = createFeatureSelector<AuthState>('auth');

export const selectAuth = createSelector(selectFeature, (state) => state);

export const selectIsLoggedIn = createSelector(
  selectFeature,
  (state) => !!state.accessToken
);

export const selectToken = createSelector(
  selectFeature,
  (state) => state.accessToken
);

export const selectDecodedToken = createSelector(
  selectFeature,
  (state) => state.decodedToken
);

export const selectAccessType = createSelector(
  selectFeature,
  (state) => state?.accessType ?? state.decodedToken?.accessType
);

export const selectCepResponseCreateBroker = createSelector(
  selectAuth,
  (state) => state.cepResponseBrokerProtheus
);

export const selectBrokerProtheus = createSelector(
  selectAuth,
  (state) => state.brokerProtheus
);

export const selectBrokerCPF = createSelector(
  selectAuth,
  (state) => state.brokerCPF
);

export const selectBrokerCode = createSelector(
  selectAuth,
  (state) => state?.brokerCode
);
