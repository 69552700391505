import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DefaultResponse } from 'src/app/core/models/DefaultResponse';

import { CepIBGEResponse } from 'src/app/features/sale/interfaces/CepIBGEResponse';
import endpoints from 'src/environments/endpoints';

@Injectable({
  providedIn: 'root',
})
export class CepService {
  constructor(private http: HttpClient) {}

  getCepData(cep: string): Observable<CepIBGEResponse> {
    const baseURL = endpoints.cepsIBGE;
    const url = `${baseURL}/cep/${cep}`;

    return this.http.get<DefaultResponse<CepIBGEResponse>>(url).pipe(
      map((res) => res?.data),
      map((response) => {
        if (!response.cep) {
          throw new Error('');
        }
        return response;
      })
    );
  }
}
