<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="row mt-2">
    <!-- CPF -->
    <mat-form-field
      [ngClass]="!brokerProtheus ? 'w-100' : 'col-lg-3 col-md-4'"
      *ngIf="accessType === 1"
    >
      <mat-label> CPF </mat-label>

      <input
        matInput
        type="text"
        inputmode="numeric"
        mask="000.000.000-00"
        formControlName="cpf"
      />

      <mat-icon
        [class.text-success]="formGroup.controls.cpf.valid"
        *ngIf="formGroup.controls.cpf.value"
        matSuffix
      >
        {{ formGroup.controls.cpf.hasError("invalidCpf") ? "close" : "done" }}
      </mat-icon>

      <mat-error *ngIf="formGroup.controls.cpf?.hasError('required')">
        CPF é obrigatório
      </mat-error>

      <mat-error *ngIf="formGroup.controls.cpf.hasError('invalidCpf')">
        Digite um CPF válido
      </mat-error>
    </mat-form-field>

    <ng-container *ngIf="accessType === 1 && brokerProtheus">
      <!-- Nome completo -->
      <mat-form-field class="col-lg-3 col-md-8">
        <mat-label> Nome Completo</mat-label>

        <input type="text" matInput formControlName="nomeCompleto" />

        <mat-error
          *ngIf="formGroup.controls.nomeCompleto?.hasError('invalidName')"
        >
          {{ formGroup.controls.nomeCompleto.getError("invalidName") }}
        </mat-error>

        <mat-error
          *ngIf="formGroup.controls.nomeCompleto?.hasError('required')"
        >
          Nome é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Data de Nascimento -->
      <mat-form-field class="col-lg-3 col-md-6">
        <mat-label> Data de Nascimento </mat-label>

        <input
          type="text"
          inputmode="numeric"
          matInput
          formControlName="dataNascimento"
          mask="00/00/0000"
        />

        <mat-error *ngIf="formGroup.controls.dataNascimento?.hasError('mask')">
          Preencha este campo corretamente
        </mat-error>

        <mat-error
          *ngIf="formGroup.controls.dataNascimento?.hasError('required')"
        >
          Data de Nascimento é obrigatória
        </mat-error>
      </mat-form-field>

      <!-- Sexo -->
      <mat-form-field class="col-lg-3 col-md-6">
        <mat-label> Sexo </mat-label>

        <mat-select formControlName="sexo">
          <mat-option value="M"> Masculino </mat-option>
          <mat-option value="F"> Feminino </mat-option>
        </mat-select>

        <mat-error *ngIf="formGroup.controls.sexo?.hasError('required')">
          Sexo é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Número do Celular com DDD -->
      <mat-form-field class="col-lg-4 col-md-6">
        <mat-label> Número do Celular com DDD </mat-label>

        <input
          type="text"
          inputmode="numeric"
          matInput
          formControlName="celular"
          [dropSpecialCharacters]="true"
          mask="(00) 0 0000-0000"
        />

        <mat-error *ngIf="formGroup.controls.celular?.hasError('mask')">
          Preencha este campo corretamente
        </mat-error>

        <mat-error *ngIf="formGroup.controls.celular?.hasError('required')">
          Telefone é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Número do Telefone com DDD -->
      <mat-form-field class="col-lg-4 col-md-6">
        <mat-label> Número do Telefone com DDD </mat-label>

        <input
          type="text"
          inputmode="numeric"
          matInput
          formControlName="telefone"
          [dropSpecialCharacters]="true"
          mask="(00) 0000-0000"
        />

        <mat-error *ngIf="formGroup.controls.telefone?.hasError('mask')">
          Preencha este campo corretamente
        </mat-error>
      </mat-form-field>

      <!-- E-mail -->
      <mat-form-field class="col-lg-4">
        <mat-label> E-mail </mat-label>

        <input type="text" matInput formControlName="email" />

        <mat-error *ngIf="formGroup.controls.email.hasError('email')">
          E-mail inválido
        </mat-error>

        <mat-error *ngIf="formGroup.controls.email.hasError('required')">
          E-mail é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- CEP -->
      <mat-form-field class="col-lg-3">
        <mat-label> CEP </mat-label>

        <input
          type="text"
          inputmode="numeric"
          matInput
          formControlName="cep"
          mask="00000-000"
          (blur)="onBlurQueryCep()"
        />

        <mat-error *ngIf="formGroup.controls.cep.hasError('mask')">
          Preencha este campo corretamente
        </mat-error>

        <mat-error *ngIf="formGroup.controls.cep.hasError('required')">
          CEP é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Endereço -->
      <mat-form-field class="col-lg-3 col-md-10">
        <mat-label> Endereço </mat-label>

        <input type="text" matInput formControlName="endereco" />

        <mat-error *ngIf="formGroup.controls.endereco.hasError('required')">
          Endereço é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Número -->
      <mat-form-field class="col-lg-3 col-md-2">
        <mat-label> Número </mat-label>

        <input
          type="text"
          inputmode="numeric"
          matInput
          formControlName="numero"
        />

        <mat-error *ngIf="formGroup.controls.numero.hasError('required')">
          Número é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Complemento -->
      <mat-form-field class="col-lg-3 col-md-6">
        <mat-label> Complemento </mat-label>

        <input type="text" matInput formControlName="complemento" />
      </mat-form-field>

      <!-- Bairro -->
      <mat-form-field class="col-lg-4 col-md-6">
        <mat-label> Bairro </mat-label>

        <input type="text" matInput formControlName="bairro" />

        <mat-error *ngIf="formGroup.controls.bairro.hasError('required')">
          Bairro é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Cidade -->
      <mat-form-field class="col-lg-4 col-md-10">
        <mat-label> Cidade </mat-label>

        <input type="text" matInput formControlName="cidade" />

        <mat-error *ngIf="formGroup.controls.cidade?.hasError('required')">
          Cidade é obrigatória
        </mat-error>
      </mat-form-field>

      <!-- Estado -->
      <mat-form-field class="col-lg-4 col-md-2">
        <mat-label> Estado </mat-label>

        <input type="text" matInput formControlName="estado" mask="SS" />

        <mat-error *ngIf="formGroup.controls.estado.hasError('mask')">
          Preencha este campo corretamente
        </mat-error>

        <mat-error *ngIf="formGroup.controls.estado.hasError('required')">
          Estado é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Login -->
      <mat-form-field class="col-lg-6">
        <mat-label> Login </mat-label>

        <input type="text" matInput formControlName="login" />

        <mat-error *ngIf="formGroup.controls.login.hasError('minlength')">
          O login deve ter ao menos 4 caracteres
        </mat-error>

        <mat-error *ngIf="formGroup.controls.login?.hasError('invalidLogin')">
          {{ formGroup.controls.login.getError("invalidLogin") }}
        </mat-error>

        <mat-error *ngIf="formGroup.controls.login?.hasError('required')">
          Login é obrigatório
        </mat-error>
      </mat-form-field>

      <!-- Senha -->
      <mat-form-field appearance="outline" class="col-lg-6">
        <mat-label>Senha</mat-label>
        <input
          matInput
          [type]="showPass ? 'text' : 'password'"
          name="senha"
          formControlName="senha"
        />
        <mat-icon
          [class.text-success]="formGroup.controls.senha.valid"
          matSuffix
          *ngIf="!showPass && formGroup.controls.senha.value"
          (click)="toggleShowPass()"
        >
          lock
        </mat-icon>

        <mat-icon
          matSuffix
          *ngIf="showPass && formGroup.controls.senha.value"
          (click)="toggleShowPass()"
        >
          lock_open
        </mat-icon>

        <mat-error *ngIf="formGroup.controls.senha.hasError('required')">
          Senha é obrigatória
        </mat-error>

        <mat-error *ngIf="formGroup.controls.senha.hasError('minlength')">
          A senha deve ter ao menos 8 caracteres
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>

  <div
    class="col-12 d-flex justify-content-between my-2"
    *ngIf="accessType !== 2"
  >
    <button
      mat-stroked-button
      type="button"
      class="back-button"
      (click)="onBack()"
    >
      Voltar
    </button>

    <button
      mat-raised-button
      type="button"
      class="confirm-button"
      *ngIf="accessType === 1 && !brokerProtheus"
      (click)="getBrokerByCpf()"
      [disabled]="formGroup.controls.cpf.invalid"
    >
      Buscar
    </button>

    <button
      type="submit"
      mat-raised-button
      class="confirm-button"
      *ngIf="accessType === 1 && brokerProtheus"
    >
      Salvar
    </button>
  </div>
</form>

<ng-container *ngIf="brokerProtheus">
  <small
    appCapsLock
    (capsLockOn)="setCapsLockOn($event)"
    [class.d-none]="!isCapsLockOn"
  >
    CAPS LOCK ATIVADO
  </small>
</ng-container>
