import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { City } from 'src/app/models/City';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DefaultResponse } from 'src/app/core/models/DefaultResponse';
import endpoints from 'src/environments/endpoints';
import { State } from 'src/app/models/State';
import { states } from 'src/app/data/states';

@Injectable({
  providedIn: 'root',
})
export class LocationFilterService {
  constructor(private http: HttpClient) {}

  getUFs(): Observable<State[]> {
    return of([...states]).pipe(map((uf) => this.sortUFs(uf)));
  }

  getCitiesIBGE(uf: State): Observable<City[]> {
    const baseURL = endpoints.citiesIBGE;
    const url = `${baseURL}/idUf/${uf.id}`;

    return this.http.get<DefaultResponse<City[]>>(url).pipe(
      map((res) => res?.data),
      map((cities) => this.sortCities(cities))
    );
  }

  private sortUFs(ufs: State[]): State[] {
    return ufs?.sort((a, b) =>
      a.nome > b.nome ? 1 : a.nome < b.nome ? -1 : 0
    );
  }

  private sortCities(cities: City[]): City[] {
    return cities?.sort((a, b) =>
      a.nome > b.nome ? 1 : a.nome < b.nome ? -1 : 0
    );
  }
}
