import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  showLoader$: Observable<boolean>;
  loaderText$ = new BehaviorSubject<string | string[]>('');

  private openedLoaders$ = new BehaviorSubject<string[]>([]);

  constructor() {
    this.showLoader$ = this.openedLoaders$.pipe(map((n) => n.length > 0));
  }

  hide(): void {
    const [, ...loaders] = this.openedLoaders$.getValue();
    this.openedLoaders$.next(loaders);
  }

  show(loaderText: string = '', multi = false): void {
    if (multi) {
      this.setLoaderTextMultiple(loaderText);
      return;
    }

    this.setLoaderText(loaderText);
  }

  private setLoaderTextMultiple(loaderText: string): void {
    this.loaderText$.next([
      loaderText,
      'Estamos processando sua solicitação...',
      'Recebendo resposta do servidor...',
      'Fique tranquilo, estamos quase lá...',
    ]);

    this.openedLoaders$.next([...this.openedLoaders$.getValue(), loaderText]);
  }

  private setLoaderText(loaderText: string): void {
    this.loaderText$.next(loaderText);
    this.openedLoaders$.next([...this.openedLoaders$.getValue(), loaderText]);
  }
}
