import { Injectable } from '@angular/core';
import { darken, lighten } from 'polished';
import { Enterprise } from 'src/app/models/Enterprise';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  setEnterpriseCssMainColors(enterprise: Enterprise): void {
    const { style } = document.body;

    const primaryColor = `#${enterprise.primaryColor}`;
    const secondaryColor = `#${enterprise.secondaryColor}`;

    style.setProperty('--primaryColor', primaryColor);

    style.setProperty('--lighterPrimaryColor', lighten(0.3, primaryColor));

    style.setProperty('--lightPrimaryColor', lighten(0.1, primaryColor));

    style.setProperty('--darkPrimaryColor', darken(0.2, primaryColor));

    style.setProperty('--darkerPrimaryColor', darken(0.3, primaryColor));

    style.setProperty('--secondaryColor', secondaryColor);

    style.setProperty('--lighterSecondaryColor', lighten(0.3, secondaryColor));

    style.setProperty('--lightSecondaryColor', lighten(0.1, secondaryColor));

    style.setProperty('--darkSecondaryColor', darken(0.2, secondaryColor));

    style.setProperty('--darkerSecondaryColor', darken(0.3, secondaryColor));
  }
}
