import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { NgxMaskModule } from 'ngx-mask';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AuthRoutingModule } from './auth-routing.module';
import { ComponentsModule } from '../../core/components/components.module';

import { AuthComponent } from './components/auth';
import { LoginComponent } from './components/login';
import { LogoutComponent } from './components/logout';
import { ResetPassComponent } from './components/reset-pass';
import { CreateUserComponent } from './components/create-user';
import { RequestPassComponent } from './components/request-pass';
import { CreateUserFormComponent } from './components/create-user-form';
import { CreateBrokerUserFormComponent } from './components/create-broker-user-form';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    ResetPassComponent,
    CreateUserComponent,
    RequestPassComponent,
    CreateUserFormComponent,
    CreateBrokerUserFormComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    ComponentsModule,
    FontAwesomeModule,
    AuthRoutingModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonToggleModule,

    NgxMaskModule.forRoot(),
  ],
})
export class AuthModule {}
