import { createAction, props } from '@ngrx/store';
import { CepIBGEResponse } from 'src/app/features/sale/interfaces/CepIBGEResponse';
import { Client } from 'src/app/models/Client';

import { Enterprise } from 'src/app/models/Enterprise';
import { SystemSegment } from 'src/app/models/SystemSegment';
import { Prop } from '../../models/Prop';
import { AddPlanToShoppingCartPayload } from '../../models/shopping-cart/AddPlanToShoppingCartPayload';
import { ShoppingCart } from '../../models/shopping-cart/ShoppingCart';

export enum CoreActions {
  FindClientByDocNumber = '[Core] Find Client By DocNumber',
  SetNewClientFlow = '[Core] Set New Client Flow',
  SetFormatedClientInfo = 'Set Formated Client Info',

  LoadEnterprise = '[Core] Load Enterprise',
  SetEnterprise = '[Core] Set Enterprise',

  ClearCoreState = '[Core] Clear Core State',

  CookieAcceptanceSuccess = '[Core] Cookie Acceptance Success',
  CookieAccepted = '[Core] Cookie Accepted',
  ShowCookieAcceptance = '[Core] Show Cookie Acceptance',

  SetAppVersion = '[Core] Set App Version',
  SetSystemSegment = '[Core] Set System Segment',
  SetShowHeader = '[Core] Set Show Header',
  SetShowMenuList = '[Core] Set Show Menu List',

  InitSaleRequest = '[Core] Init Sale Request',
  InitSaleSuccess = '[Core] Init Sale Success',
  InitSaleError = '[Core] Init Sale Error',

  ToggleCartPopup = '[Core] Toggle Cart Popup',
  ShowCartPopup = '[Core] Show Cart Popup',
  HideCartPopup = '[Core] Hide Cart Popup',

  SetPlanToCartRequest = '[Core] Set Plan To Cart Request',
  SetPlanToCartSuccess = '[Core] Set Plan To Cart Success',
  SetPlanToCartError = '[Core] Set Plan To Cart Error',

  RemovePlanCartRequest = '[Core] Remove Plan Cart Request',
  RemovePlanCartSuccess = '[Core] Remove Plan Cart Success',
  RemovePlanCartError = '[Core] Remove Plan Cart Error',

  SetShoppingCart = '[Core] Set Shopping Cart',

  ClearCartRequest = '[Core] Clear Cart Request',
  ClearCartSuccess = '[Core] Clear Cart Success',
  ClearCartError = '[Core] Clear Cart Error',

  LoadCartRequest = '[Core] Load Cart Request',

  SetIsLoadingCart = '[Core] Set Is Loading Cart',

  SetIsSaleFlow = '[Core] Set Is Sale Flow',

  GoToSimulationPage = '[Core] Go To Simulation Page',
  SetSimulationStep = '[Core] Set Simulation Step',
  ClearSimulationStep = '[Core] Clear Simulation Step',

  SetScreenWidth = '[Core] Set Screen Width',
}

export const findClientByDocNumber = createAction(
  CoreActions.FindClientByDocNumber,
  props<Prop<string>>()
);

export const setNewClientFlow = createAction(
  CoreActions.SetNewClientFlow,
  props<Prop<boolean>>()
);

export const clearCoreState = createAction(CoreActions.ClearCoreState);

export const showCookieAcceptance = createAction(
  CoreActions.ShowCookieAcceptance
);

export const cookieAcceptanceSuccess = createAction(
  CoreActions.CookieAcceptanceSuccess
);

export const cookieAccepted = createAction(CoreActions.CookieAccepted);

export const setEnterprise = createAction(
  CoreActions.SetEnterprise,
  props<Prop<Enterprise>>()
);

export const loadEnterprise = createAction(CoreActions.LoadEnterprise);

export const setAppVersion = createAction(
  CoreActions.SetAppVersion,
  props<Prop<string>>()
);

export const setShowHeader = createAction(
  CoreActions.SetShowHeader,
  props<Prop<boolean>>()
);

export const setShowMenuList = createAction(
  CoreActions.SetShowMenuList,
  props<Prop<boolean>>()
);

export const setFormatedClientInfo = createAction(
  CoreActions.SetFormatedClientInfo,
  props<Prop<Client>>()
);

export const initSaleRequest = createAction(CoreActions.InitSaleRequest);

export const initSaleSuccess = createAction(CoreActions.InitSaleSuccess);

export const setSystemSegment = createAction(
  CoreActions.SetSystemSegment,
  props<Prop<SystemSegment>>()
);

export const initSaleError = createAction(CoreActions.InitSaleError);

export const toggleCartPopup = createAction(CoreActions.ToggleCartPopup);

export const showCartPopup = createAction(CoreActions.ShowCartPopup);

export const hideCartPopup = createAction(CoreActions.HideCartPopup);

export const setPlanToCartRequest = createAction(
  CoreActions.SetPlanToCartRequest,
  props<Prop<AddPlanToShoppingCartPayload>>()
);

export const setPlanToCartSuccess = createAction(
  CoreActions.SetPlanToCartSuccess
);

export const removePlanCartRequest = createAction(
  CoreActions.RemovePlanCartRequest,
  props<Prop<string>>()
);
export const removePlanCartSuccess = createAction(
  CoreActions.RemovePlanCartSuccess
);
export const removePlanCartError = createAction(
  CoreActions.RemovePlanCartError
);

export const setPlanToCartError = createAction(CoreActions.SetPlanToCartError);

export const setShoppingCart = createAction(
  CoreActions.SetShoppingCart,
  props<Prop<ShoppingCart | undefined>>()
);

export const clearCartRequest = createAction(CoreActions.ClearCartRequest);

export const clearCartSuccess = createAction(CoreActions.ClearCartSuccess);

export const clearCartError = createAction(CoreActions.ClearCartError);

export const setIsLoadingCart = createAction(
  CoreActions.SetIsLoadingCart,
  props<Prop<boolean>>()
);

export const loadCartRequest = createAction(CoreActions.LoadCartRequest);

export const setIsSaleFlow = createAction(
  CoreActions.SetIsSaleFlow,
  props<Prop<boolean>>()
);

export const goToSimulationPage = createAction(CoreActions.GoToSimulationPage);

export const setScreenWidth = createAction(
  CoreActions.SetScreenWidth,
  props<Prop<number>>()
);

export const setSimulationStep = createAction(
  CoreActions.SetSimulationStep,
  props<Prop<number>>()
);

export const clearSimulationStep = createAction(
  CoreActions.ClearSimulationStep
);
