import { Pipe, PipeTransform } from '@angular/core';
import { CadastralMovementImportStatus } from 'src/app/features/sale/enums/CadastralMovementImportStatus';

@Pipe({
  name: 'importStatus',
})
export class ImportStatusPipe implements PipeTransform {
  transform(status: CadastralMovementImportStatus): string {
    const labels = {
      0: 'Erro',
      1: 'Em Andamento',
      2: 'Parcialmente Concluído',
      3: 'Concluído',
    };

    return labels[status];
  }
}
