<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    name="cpf"
    type="text"
    inputmode="numeric"
    [formControl]="control"
    (blur)="validateField()"
    (blur)="onBlur()"
    mask="000.000.000-00"
  />
  <mat-icon *ngIf="icon || (control.errors && wasValidated)" matSuffix>
    {{ control.errors ? "close" : icon }}
  </mat-icon>
  <mat-icon
    *ngIf="icon || (control.value && !control.errors && wasValidated)"
    matSuffix
  >
    done
  </mat-icon>
  <mat-error *ngIf="control.hasError('required')">
    Campo obrigatório
  </mat-error>
  <mat-error *ngIf="control.hasError('invalidCpf')">
    Digite um CPF válido
  </mat-error>
</mat-form-field>
