import { Pipe, PipeTransform } from '@angular/core';

import { BeneficiaryType } from 'src/app/features/sale/types/BeneficiaryType';

@Pipe({
  name: 'beneficiaryType',
})
export class BeneficiaryTypePipe implements PipeTransform {
  transform(beneficiaryType: BeneficiaryType | string): string {
    return beneficiaryType === 'T' ? 'Titular' : 'Dependente';
  }
}
