import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent extends CustomInput {
  @Input() label!: string;
  @Input() type = 'text';
  @Input() icon!: string;
  @Input() mask!: string;
  @Input() placeholder!: string;
  @Output() blured = new EventEmitter();

  override control = new UntypedFormControl(null);
  override errorList = ['mask'];

  onBlur(): void {
    this.blured.emit();
  }
}
