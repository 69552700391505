import { createReducer, on } from '@ngrx/store';

import { Prop } from 'src/app/core/models/Prop';
import { City } from 'src/app/models/City';
import { State } from 'src/app/models/State';
import {
  clearInputsState,
  getCitiesSuccess,
  getUFsSuccess,
} from './inputs.action';
import { InputsState } from './inputs.interfaces';

const initialState: InputsState = {};

const _clearInputsState = (): InputsState => ({
  ufs: [],
  cities: [],
});

const _getUFsSuccess = (
  state: InputsState,
  action: Prop<State[]>
): InputsState => ({
  ...state,
  ufs: action.payload,
});

const _getCitiesSuccess = (
  state: InputsState,
  action: Prop<City[]>
): InputsState => ({
  ...state,
  cities: action.payload,
});

export const inputsReducer = createReducer(
  initialState,

  on(clearInputsState, _clearInputsState),
  on(getUFsSuccess, _getUFsSuccess),
  on(getCitiesSuccess, _getCitiesSuccess)
);
