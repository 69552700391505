import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, interval, pipe, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { clearCoreState } from 'src/app/core/store/core/core.actions';
import { AppState } from 'src/app/core/store/core/core.interfaces';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnDestroy {
  text$ = new BehaviorSubject('.');
  destroyed$ = new Subject<void>();

  constructor(private router: Router, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.setLoader();
    this.store.dispatch(clearCoreState());
    this.router.navigate(['autenticacao', 'entrar']);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setLoader(): void {
    interval(1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const currentDots = this.text$.getValue();
        if (currentDots.length >= 3) {
          this.text$.next('.');
          return;
        }

        this.text$.next(`${currentDots}.`);
      });
  }
}
