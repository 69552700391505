import { Component, Input, OnInit } from '@angular/core';
import { AgeRange } from 'src/app/models/AgeRange';
import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-input-age-range',
  templateUrl: './input-age-range.component.html',
  styleUrls: ['./input-age-range.component.scss'],
})
export class InputAgeRangeComponent extends CustomInput implements OnInit {
  @Input() ageRange!: AgeRange;

  override ngOnInit(): void {
    this.registerChanges();
    this.control.setValue({
      initialRange: this.ageRange.initialRange,
      finalRange: this.ageRange.finalRange,
      lifeAmount: this.ageRange.lifeAmount,
      valueAmount: this.ageRange.valueAmount,
    });
  }

  increment(): void {
    this.control.setValue({
      ...this.control.value,
      lifeAmount: this.control.value.lifeAmount + 1,
    });
    this.changed.emit(this.control.value);
  }

  decrement(): void {
    if (this.control.value.lifeAmount >= 1) {
      this.control.setValue({
        ...this.control.value,
        lifeAmount: this.control.value.lifeAmount - 1,
      });
      this.changed.emit(this.control.value);
    }
  }

  getValue(): number {
    return {
      ...this.value,
      lifeAmount: Number(this.value?.lifeAmount),
    };
  }

  setControlValue(input: any, value: string): void {
    if (!isNaN(Number(value))) {
      this.control.setValue({
        initialRange: this.ageRange.initialRange,
        finalRange: this.ageRange.finalRange,
        lifeAmount: Number(value),
        valueAmount: this.ageRange.valueAmount,
      });

      setTimeout(() => (input.value = String(Number(value))), 1);
    }
  }
}
