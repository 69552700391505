import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SimulationState } from './simulation.interfaces';

const selectFeature = createFeatureSelector<SimulationState>('simulation');

export const selectShowSignInModal = createSelector(
  selectFeature,
  (state) => state.showSignInModal
);

export const selectSentSimulation = createSelector(
  selectFeature,
  (state) => state?.sentSimulation
);

export const selectCreatedSimulationId = createSelector(
  selectFeature,
  (state) => state.createdSimulationId
);

export const selectCompany = createSelector(
  selectFeature,
  (state) => state.company
);

export const selectSimulations = createSelector(
  selectFeature,
  (state) => state.simulations
);

export const selectTotalSimulations = createSelector(
  selectFeature,
  (state) => state.totalSimulation
);

export const selectSimilarPlansSelected = createSelector(
  selectFeature,
  (state) => state.similarPlansSelected
);

export const selectSimilarPlansChanged = createSelector(
  selectFeature,
  (state) => state.similarPlansChanged
);

export const mostExpensiveSelectedSimilarPlanValue = createSelector(
  selectFeature,
  (state) =>
    Math.max(
      ...(state?.similarPlansSelected?.map((a) => a.currentValue) || [0])
    )
);

export const selectSimilarPlans = createSelector(
  selectFeature,
  (state) => state?.similarPlans
);

export const selectSimilarPlansAmount = createSelector(
  selectFeature,
  (state) => state?.similarPlans?.length
);

export const selectSimulation = createSelector(selectFeature, (state) => state);

export const selectCurrentOperators = createSelector(
  selectFeature,
  (state) => state.currentOperators
);
export const selectDocNumber = createSelector(
  selectFeature,
  (state) => state.docNumber
);

export const contractedPlansValue = createSelector(
  selectFeature,
  (state) =>
    state.client?.contractedPlan?.reduce(
      (acc, plan) => acc + plan.currentValue,
      0
    ) ?? 0
);

export const hasContractedPlans = createSelector(
  selectFeature,
  (state) => !!state.client?.contractedPlan
);

export const selectIsNewClient = createSelector(
  selectFeature,
  (state) => !state.client?.contractedPlan?.length
);

export const selectPreferences = createSelector(
  selectFeature,
  (state) => state.preferences
);

export const selectEntityCode = createSelector(
  selectFeature,
  (state) => state.entityCode
);

export const selectClient = createSelector(
  selectFeature,
  (state) => state.client
);

export const selectAgeRanges = createSelector(
  selectFeature,
  (state) => state.ageRanges
);

export const selectTargetAudience = createSelector(
  selectFeature,
  (state) => state.targetAudience
);

export const selectShowClientNotFoundMsg = createSelector(
  selectFeature,
  (state) => state.showClientNotFoundMsg
);

export const selectLifesAmount = createSelector(
  selectFeature,
  (state) => state.lifesAmount
);

export const selectShowMsgNoSimulationsData = createSelector(
  selectFeature,
  (state) => state.showMsgNoSimulationsData
);
