<div class="not-found-container">
  <div class="image-container">
    <img class="image-404" src="../../../assets/images/404-image.svg" />
  </div>
  <div class="text-container">
    <h1 class="default-title-1">Página não encontrada</h1>
    <app-button
      linkTo="/"
      color="primary"
      class="my-4"
      label="Voltar para o início"
    >
    </app-button>
  </div>
</div>
