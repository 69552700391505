<div class="app-container">
  <app-header></app-header>
  <div class="main-container">
    <router-outlet class="d-none"></router-outlet>
  </div>
  <app-footer></app-footer>
</div>

<app-loader></app-loader>

<app-cookie-acceptance></app-cookie-acceptance>
