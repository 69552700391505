import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NonNullableFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, Subject, takeUntil } from 'rxjs';
import { signOnRequest } from 'src/app/core/store/auth/auth.actions';
import { AppState } from 'src/app/core/store/core/core.interfaces';
import { selectSystemSegment } from 'src/app/core/store/core/core.selectors';
import { showNotificationInfo } from 'src/app/core/store/notification/notification.actions';
import FormChecker from 'src/app/core/utils/form-checker';
import { getPhoneMask } from 'src/app/core/utils/get-phone-mask';
import { SignOnPayload } from 'src/app/features/auth/interfaces/SignOnPayload';
import { SystemSegment } from 'src/app/models/SystemSegment';

@Component({
  selector: 'app-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss'],
})
export class CreateUserFormComponent implements OnInit, OnDestroy {
  formGroup = this.buildForm();

  showPass = false;

  systemSegment!: SystemSegment;

  destroyed$ = new Subject<void>();

  phoneMask!: string;

  phoneIcon!: string;

  isCapsLockOn!: boolean;

  constructor(
    private fb: NonNullableFormBuilder,
    private store: Store<AppState>,
    private cd: ChangeDetectorRef
  ) {}

  get cpfControl(): FormControl {
    return this.formGroup.controls.cpf as FormControl;
  }

  ngOnInit(): void {
    this.setPhoneMask();
    this.setPhoneIcon();
    this.setSystemSegment();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggleShowPass(): void {
    this.showPass = !this.showPass;
  }

  setCapsLockOn(event: boolean): void {
    this.isCapsLockOn = event;
  }

  getSignOnPayload(): SignOnPayload {
    const form = this.formGroup.getRawValue();

    return {
      name: form.name,
      email: form.email,
      telephoneNumber: form.telephone,
      cpf: form.cpf,
      password: form.password.trim(),
      confirmPassword: form.confirmPassword.trim(),
      accessType: 2,
    };
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.store.dispatch(
        showNotificationInfo({ payload: 'Preencha os campos corretamente.' })
      );
      return;
    }

    this.store.dispatch(signOnRequest({ payload: this.getSignOnPayload() }));
  }

  private setPhoneMask(): void {
    const telephone = this.formGroup.get('telephone');

    telephone?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.phoneMask = getPhoneMask(value);
        this.cd.detectChanges();
      });
  }

  private setPhoneIcon(): void {
    const telephone = this.formGroup.get('telephone');

    telephone?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value.length === 10) {
          telephone.setErrors(null);
          this.phoneIcon = 'call';
          return;
        }

        if (value.length === 11) {
          telephone.setErrors(null);
          this.phoneIcon = 'phone_iphone';
          return;
        }

        this.cd.detectChanges();
      });
  }

  private setSystemSegment(): void {
    this.store
      .select(selectSystemSegment)
      .pipe(takeUntil(this.destroyed$), filter(Boolean))
      .subscribe((systemSegment) => {
        this.systemSegment = systemSegment;
      });
  }

  private buildForm() {
    return this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, FormChecker.email]],
      telephone: ['', Validators.required],
      cpf: ['', [Validators.required, FormChecker.cpf]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.confirmPasswordValidator(),
        ],
      ],
    });
  }

  private confirmPasswordValidator(): ValidatorFn {
    return (confirmPasswordControl: AbstractControl) => {
      const confirmPasswordValue = confirmPasswordControl.value;
      const passwordValue = this.formGroup?.controls.password?.value;

      if (!passwordValue) {
        return null;
      }

      return confirmPasswordValue === passwordValue
        ? null
        : { differentPass: true };
    };
  }
}
