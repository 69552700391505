import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { Enterprise } from 'src/app/models/Enterprise';
import { SystemSegment } from 'src/app/models/SystemSegment';
import { Store } from '@ngrx/store';
import { selectToken } from '../../store/auth/auth.selectors';
import { first, switchMap } from 'rxjs/operators';
import {
  selectTenantIdWithBranch,
  selectTenantIdWithoutBranch,
} from '../../store/core/core.selectors';

export const SHOULD_ADD_TENANT_WITHOUT_BRANCH = new HttpContextToken(
  () => false
);

export const SHOULD_ADD_TENANT = new HttpContextToken(() => true);

export const SHOULD_ADD_BEARER_TOKEN = new HttpContextToken(() => true);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  enterprise!: Enterprise;
  systemSegment!: SystemSegment;

  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return combineLatest([
      this.store.select(selectToken),
      this.store.select(selectTenantIdWithBranch),
      this.store.select(selectTenantIdWithoutBranch),
    ]).pipe(
      first(),
      switchMap(([token, tenantIdWithBranch, tenantIdWithoutBranch]) => {
        if (token && this.shouldAddBearerToken(request)) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        const hasTenantId = !!tenantIdWithBranch;
        if (
          hasTenantId &&
          !this.alreadyHasTenantId(request) &&
          this.shouldAddTenantId(request)
        ) {
          const tenantId = this.shouldAddTenantIdWithoutBranch(request)
            ? tenantIdWithoutBranch
            : tenantIdWithBranch;

          if (tenantId) {
            request = request.clone({
              headers: request.headers.set('TenantId', tenantId),
            });
          }
        }

        return next.handle(request);
      })
    );
  }

  private alreadyHasTenantId(request: HttpRequest<unknown>): boolean {
    return !!request.headers.get('tenantId');
  }

  private shouldAddTenantIdWithoutBranch(
    request: HttpRequest<unknown>
  ): boolean {
    return request.context.get(SHOULD_ADD_TENANT_WITHOUT_BRANCH);
  }

  private shouldAddTenantId(request: HttpRequest<unknown>): boolean {
    return request.context.get(SHOULD_ADD_TENANT);
  }

  private shouldAddBearerToken(request: HttpRequest<unknown>): boolean {
    return request.context.get(SHOULD_ADD_BEARER_TOKEN);
  }
}
