<mat-form-field appearance="outline" class="w-100" (click)="validateField()">
  <mat-label>{{
    entities.length ? label : "Selecione uma Entidade da Classe"
  }}</mat-label>
  <mat-select [formControl]="control">
    <mat-option *ngFor="let entity of entities" [value]="entity.codeEntity">
      {{ entity.entity }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="control.hasError('required')">
    Campo obrigatório
  </mat-error>
</mat-form-field>
