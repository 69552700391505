import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { filter, Subject, takeUntil } from 'rxjs';
import {
  setAccessType,
  signInBrokerCompanyRequest,
  signInBrokerRequest,
  signInClientRequest,
} from 'src/app/core/store/auth/auth.actions';
import { AppState } from 'src/app/core/store/core/core.interfaces';
import { showNotificationInfo } from 'src/app/core/store/notification/notification.actions';
import { BrokerSignInPayload } from 'src/app/features/auth/interfaces/BrokerSignInPayload';
import { ClientSignInPayload } from 'src/app/features/auth/interfaces/ClientSignInPayload';
import { AccessType } from '../../enums/AccessType';
import { CustomErrorStateMatcher } from '../../utils/CustomErrorStateMatcher';
import FormChecker from '../../utils/form-checker';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: CustomErrorStateMatcher,
    },
  ],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Output() finished = new EventEmitter();

  formGroup = this.buildForm();

  destroyed$ = new Subject<void>();

  showPass = false;
  readonly = true;
  isCapsLockOn!: boolean;
  isFormSubmitted = false;
  accessType?: AccessType;

  constructor(
    private store: Store<AppState>,
    private fb: NonNullableFormBuilder
  ) {}

  get shouldShowAccessTypeMsgError(): boolean {
    return (
      !!this.formGroup.controls.accessType?.hasError('required') &&
      this.isFormSubmitted
    );
  }

  ngOnInit(): void {
    this.buildForm();
    this.setAccessTypeListener();
    this.disableInputAutocomplete();
    this.setFormValidatorsListener();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSubmit(): void {
    this.isFormSubmitted = true;
    console.log(this.formGroup);
    if (this.formGroup.invalid) {
      this.store.dispatch(
        showNotificationInfo({ payload: 'Preencha os campos corretamente.' })
      );
      return;
    }

    if (this.accessType === AccessType.Client) {
      this.signInClientRequest();
      return;
    }

    if (this.accessType === AccessType.BrokerCompany) {
      this.signInBrokerCompanyRequest();
      return;
    }

    this.signInBrokerRequest();
  }

  toggleShowPass(): void {
    this.showPass = !this.showPass;
  }

  setCapsLockOn(event: boolean): void {
    this.isCapsLockOn = event;
  }

  private setAccessTypeListener(): void {
    this.formGroup.controls.accessType.valueChanges
      .pipe(takeUntil(this.destroyed$), filter(Boolean))
      .subscribe((accessType) => {
        this.accessType = accessType;
        this.store.dispatch(setAccessType({ payload: accessType }));
      });
  }

  private signInClientRequest(): void {
    const payload = this.getClientSigInPayload();

    this.store.dispatch(signInClientRequest({ payload }));
  }

  private signInBrokerRequest(): void {
    const payload = this.getBrokerSignInPayload();

    this.store.dispatch(signInBrokerRequest({ payload }));
  }

  private signInBrokerCompanyRequest(): void {
    const payload = this.getBrokerSignInPayload();

    this.store.dispatch(signInBrokerCompanyRequest({ payload }));
  }

  private buildForm() {
    return this.fb.group({
      accessType: [0],
      username: [''],
      email: [''],
      cpf: [''],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  private setFormValidatorsListener(): void {
    this.formGroup.controls.accessType.valueChanges.subscribe((accessType) => {
      if (!accessType) {
        return;
      }

      if (accessType === AccessType.Broker) {
        this.setFormValidatorsBrokerAccess();
        return;
      }

      if (accessType === AccessType.BrokerCompany) {
        this.setFormValidatorsBrokerCompanyAccess();
        return;
      }

      this.setFormValidatorsClientAccess();
    });
  }

  private setFormValidatorsBrokerAccess(): void {
    const formControls = this.formGroup.controls;

    formControls.cpf.setErrors(null);
    formControls.cpf.setValidators(null);

    formControls.email.setErrors(null);
    formControls.email.setValidators(null);

    formControls.username.setValidators([
      Validators.required,
      Validators.minLength(4),
      FormChecker.login,
    ]);
  }

  private setFormValidatorsBrokerCompanyAccess(): void {
    const formControls = this.formGroup.controls;

    formControls.cpf.setErrors(null);
    formControls.cpf.setValidators(null);

    formControls.email.setErrors(null);
    formControls.email.setValidators(null);

    formControls.username.setValidators([
      Validators.required,
      FormChecker.cnpj,
    ]);
  }

  private setFormValidatorsClientAccess(): void {
    const formControls = this.formGroup.controls;

    formControls.username.setErrors(null);
    formControls.username.setValidators(null);

    formControls.cpf.setValidators([Validators.required, FormChecker.cpf]);
    formControls.email.setValidators([Validators.required, Validators.email]);
  }

  private getBrokerSignInPayload(): BrokerSignInPayload {
    const form = this.formGroup.getRawValue();

    return {
      username: form.username.trim(),
      password: form.password.trim(),
    };
  }

  private getBrokerCompanySignInPayload(): BrokerSignInPayload {
    const form = this.formGroup.getRawValue();

    return {
      username: form.username.trim(),
      password: form.password.trim(),
    };
  }

  private getClientSigInPayload(): ClientSignInPayload {
    const form = this.formGroup.getRawValue();

    return {
      cpf: form.cpf,
      email: form.email,
      password: form.password.trim(),
    };
  }

  private disableInputAutocomplete(): void {
    setTimeout(() => {
      this.readonly = false;
    }, 0);
  }
}
