<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="email"
    autocomplete="new-password"
    [formControl]="control"
    (blur)="validateField()"
    [readonly]="readonly"
  />

  <mat-icon *ngIf="icon || (control.errors && wasValidated)" matSuffix>
    {{ control.errors ? "close" : icon }}
  </mat-icon>
  <mat-icon
    *ngIf="icon || (control.value && !control.errors && wasValidated)"
    matSuffix
    >done</mat-icon
  >
  <mat-error *ngIf="control.hasError('required')">Campo obrigatório</mat-error>
  <mat-error *ngIf="control.hasError('invalidEmail')"
    >Digite o e-mail corretamente</mat-error
  >
</mat-form-field>
