import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapsible-filter',
  templateUrl: './collapsible-filter.component.html',
  styleUrls: ['./collapsible-filter.component.scss'],
})
export class CollapsibleFilterComponent {
  @Input() title!: string;
  @Input() showFilter = false;

  toggleShowFilter(): void {
    this.showFilter = !this.showFilter;
  }
}
