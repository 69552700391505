import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LocationFilterService } from 'src/app/core/services/location-filter';
import { LoaderService } from '../../services/loader';
import { showNotificationInfo } from '../notification/notification.actions';
import {
  getCitiesError,
  getCitiesRequest,
  getCitiesSuccess,
  getUFsRequest,
  getUFsSuccess,
} from './inputs.action';

@Injectable()
export class InputsEffects {
  getUFs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUFsRequest),
      switchMap(() => this.locationFilterService.getUFs()),
      map((ufs) => getUFsSuccess({ payload: ufs }))
    )
  );

  getCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCitiesRequest),
      distinctUntilKeyChanged('payload'),
      tap(() => this.loader.show('Carregando cidades...')),
      switchMap(({ payload: uf }) =>
        this.locationFilterService.getCitiesIBGE(uf).pipe(
          map((cities) => getCitiesSuccess({ payload: cities })),
          catchError(() => of(getCitiesError()))
        )
      ),
      tap(() => this.loader.hide())
    )
  );

  getCitiesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCitiesError),
      map(() => showNotificationInfo({ payload: 'Erro ao carregar cidades.' }))
    )
  );

  constructor(
    private actions$: Actions,
    private loader: LoaderService,
    private locationFilterService: LocationFilterService
  ) {}
}
