<div class="back-container" (click)="redirectSuccess()" *ngIf="systemSegment">
  <fa-icon class="me-2" [icon]="faAngleLeft"></fa-icon>
  Voltar a simulação /&nbsp;
  <span *ngIf="systemSegment === 'PME'">PME</span>
  <span *ngIf="systemSegment === 'Adesao'">Adesão</span>
  <span *ngIf="systemSegment === 'PF'">Individual</span>
  &nbsp;/ {{ stepDescription }}
</div>

<div class="back-container" *ngIf="!systemSegment" routerLink="/">
  <fa-icon class="me-2" [icon]="faAngleLeft"></fa-icon>
  Voltar ao início
</div>

<div class="auth-container">
  <mat-card class="m-4 shadow">
    <h1 class="auth-title">Login</h1>
    <p class="text-muted mb-3">Simulador de planos</p>

    <p class="text-default mt-4 mb-2">Escolha seu tipo de acesso</p>

    <app-login-form (successfully)="redirectSuccess()"></app-login-form>
  </mat-card>
</div>
