import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Subject } from 'rxjs';
import {
  auditTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { setScreenWidth } from './core/store/core/core.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setScreenWidth();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setScreenWidth(): void {
    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.destroyed$),
        map((event) => (event.target as Window).innerWidth),
        distinctUntilChanged(),
        auditTime(300)
      )
      .subscribe((value: number) => {
        this.store.dispatch(setScreenWidth({ payload: value }));
      });
  }
}
