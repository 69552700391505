import { Component, OnDestroy, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { SystemSegment } from 'src/app/models/SystemSegment';
import {
  selectSimulationStep,
  selectSystemSegment,
} from 'src/app/core/store/core/core.selectors';
import { AppState } from 'src/app/core/store/core/core.interfaces';
import { filter, takeUntil } from 'rxjs/operators';
import { goToSimulationPage } from 'src/app/core/store/core/core.actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { clearAuthState } from 'src/app/core/store/auth/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth/auth.component.scss', './login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  systemSegment!: SystemSegment;

  stepDescription!: string;

  faAngleLeft = faAngleLeft as IconProp;

  destroyed$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.clearAuthState();
    this.setSystemSegment();
    this.setStepDescription();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  redirectSuccess(): void {
    this.store.dispatch(goToSimulationPage());
  }

  private clearAuthState(): void {
    this.store.dispatch(clearAuthState());
  }

  private setStepDescription(): void {
    this.store
      .select(selectSimulationStep)
      .pipe(takeUntil(this.destroyed$), filter(Boolean))
      .subscribe((simulationStep) => {
        const labels: { [key: number]: string } = {
          0:
            this.systemSegment === 'PME'
              ? 'Selecionar Empresa'
              : 'Quem é Você?',
          1: 'Quantidade de Vidas',
          2: 'Preferências',
          3: 'Selecionar Planos',
          4:
            this.systemSegment === 'PME'
              ? 'Dados da Empresa'
              : 'Dados do Titular',
        };

        this.stepDescription = labels[simulationStep] ?? '';
      });
  }

  private setSystemSegment(): void {
    this.store
      .select(selectSystemSegment)
      .pipe(takeUntil(this.destroyed$), filter(Boolean))
      .subscribe((systemSegment) => {
        this.systemSegment = systemSegment;
      });
  }
}
