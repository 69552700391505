export enum ShoppingCartStatus {
  Aberto = 0,
  Andamento = 1,
  Pendente = 2,
  Finalizado = 3,
  Cancelado = 4,
  AguardandoAssinatura = 5,
  Assinado = 6,
  Devolvida = 7,
}
