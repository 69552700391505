import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './core/components/components.module';
import { AuthModule } from './features/auth/auth.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './features/home';
import { NotFoundComponent } from './features/not-found';

import { AuthEffects } from './core/store/auth/auth.effects';
import { CoreEffects } from './core/store/core/core.effects';
import { NotificationEffects } from './core/store/notification/notification.effects';

import { authReducer } from './core/store/auth/auth.reducers';
import { coreReducer } from './core/store/core/core.reducers';
import { metaReducers } from './core/store/meta-reducers';

import { environment } from 'src/environments/environment';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { AuthInterceptor } from './core/interceptors/auth';
import { RequestInterceptor } from './core/interceptors/request';

registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent, HomeComponent, NotFoundComponent],
  imports: [
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,

    ToastrModule.forRoot(),
    NgxMaskModule.forRoot({ validation: true }),

    StoreModule.forRoot(
      { core: coreReducer, auth: authReducer },
      { metaReducers }
    ),
    EffectsModule.forRoot([CoreEffects, AuthEffects, NotificationEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    SharedModule,
    ComponentsModule,
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
