import { CgcPipe } from './cgc/cgc.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimulationStatusPipe } from './simulation-status';
import { ProposalStatusPipe } from './proposal-status';
import { BeneficiaryTypePipe } from './beneficiary-type';
import { KinshipPipe } from './kinship';
import { PaymentTypePipe } from './payment-type';
import { PaymentTypeFormatPipe } from './payment-type-format';
import { CepPipe } from './cep';
import { PhonePipe } from './phone';
import { ProposalSegmentPipe } from './proposal-segment';
import { CapitalizePipe } from './capitalize';
import { ImportStatusPipe } from './import-status';
import { ImportTypePipe } from './import-type';
import { ImportStatusBadgePipe } from './import-status-badge';

const pipes = [
  SimulationStatusPipe,
  CgcPipe,
  ProposalStatusPipe,
  BeneficiaryTypePipe,
  KinshipPipe,
  PaymentTypePipe,
  PaymentTypeFormatPipe,
  CepPipe,
  PhonePipe,
  ProposalSegmentPipe,
  CapitalizePipe,
  ImportStatusPipe,
  ImportTypePipe,
  ImportStatusBadgePipe,
];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class PipesModule {}
