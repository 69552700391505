<div class="footer-container" *ngIf="enterprise$ | async as enterprise">
  <div class="enterprise-contacts-container">
    <div *ngIf="enterprise.tenantProtheus != '27'">
      <h6>Central de Atendimento</h6>
      <div class="mail-text">
        <fa-icon [icon]="faEnvelope"></fa-icon>
        {{ enterprise.callCenterEmail }}
      </div>
      <div class="phone-text">
        <fa-icon [icon]="faPhoneAlt"></fa-icon>
        {{ enterprise.callCenterTelephone }}
      </div>
    </div>
    <div *ngIf="enterprise.tenantProtheus == '27'"></div>
    <div class="version-container">
      <span>Versão: {{ appVersion }}</span>
    </div>
    <div class="logo-container">
      <img [src]="enterprise.transparentLogoUrl" class="img-logo" />
      <img [src]="enterprise.ansImage" class="ans-img" />
    </div>
  </div>
</div>
