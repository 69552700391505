<form>
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Municípío</mat-label>
    <input
      type="text"
      placeholder="Digite o nome do município"
      matInput
      [formControl]="control"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      [displayWith]="displayFn"
    >
      <mat-option *ngFor="let city of filteredCities$ | async" [value]="city">
        {{ city.nome }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.hasError('required')">
      Campo obrigatório
    </mat-error>
  </mat-form-field>
</form>
