import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simulationStatus',
})
export class SimulationStatusPipe implements PipeTransform {
  transform(value: number): unknown {
    const status: { [key: number]: string } = {
      0: 'Enviando',
      1: 'Finalizado',
      2: 'Enviado',
      3: 'Erro',
    };
    return status[value] || status[0];
  }
}
