import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  clearCartRequest,
  hideCartPopup,
  initSaleRequest,
  removePlanCartRequest,
} from 'src/app/core/store/core/core.actions';
import {
  selectCart,
  selectIsEmptyCart,
  selectIsLoadingCart,
  selectShowCartPopup,
  selectSystemSegment,
  selectTotalCartValue,
} from 'src/app/core/store/core/core.selectors';
import { selectSimulation } from 'src/app/features/simulation/store/simulation.selectors';

@Component({
  selector: 'app-cart-popup',
  templateUrl: './cart-popup.component.html',
  styleUrls: ['./cart-popup.component.scss'],
})
export class CartPopupComponent implements OnInit, OnDestroy {
  isEmptyCart!: boolean;
  destroyed$ = new Subject<void>();

  cart$ = this.store.select(selectCart);
  simulation$ = this.store.select(selectSimulation);
  systemSegment$ = this.store.select(selectSystemSegment);
  showCartPopup$ = this.store.select(selectShowCartPopup);
  isLoadingCart$ = this.store.select(selectIsLoadingCart);
  totalCartValue$ = this.store.select(selectTotalCartValue);

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setIsEmptyCart();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  hideCartPopup(): void {
    this.store.dispatch(hideCartPopup());
  }

  clearShoppingCart(): void {
    if (this.isEmptyCart) {
      return;
    }

    this.store.dispatch(clearCartRequest());
  }

  onGetPlanClick(): void {
    if (this.isEmptyCart) {
      return;
    }

    this.store.dispatch(initSaleRequest());
  }

  onRemovePlanCart(id: string): void {
    this.store.dispatch(removePlanCartRequest({ payload: id }));
  }

  private setIsEmptyCart(): void {
    this.store
      .select(selectIsEmptyCart)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isEmptyCart) => {
        this.isEmptyCart = isEmptyCart;
      });
  }
}
