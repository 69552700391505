<div class="page-container">
  <div class="home-container">
    <div class="bg"></div>
    <div class="row">
      <app-card-select-type-plan
        class="col-lg-4 py-3"
        title="Plano Empresarial"
        text="Simule aqui o plano de saúde ideal para a sua empresa."
        linkTo="/simulacao/empresarial"
      ></app-card-select-type-plan>
      <app-card-select-type-plan
        class="col-lg-4 py-3"
        title="Plano Adesão"
        text="Simule aqui o plano de saúde ideal para você e sua família, através de um vínculo profissional, classista ou setorial."
        linkTo="/simulacao/adesao"
      ></app-card-select-type-plan>
      <app-card-select-type-plan
        class="col-lg-4 py-3"
        title="Plano Individual/Familiar"
        text="Simule aqui o plano de saúde ideal para você e sua família."
        linkTo="/simulacao/individual"
      ></app-card-select-type-plan>
    </div>
  </div>
</div>
