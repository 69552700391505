import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kinship',
})
export class KinshipPipe implements PipeTransform {
  transform(kinship: string): string {
    if (!kinship) {
      return '-';
    }

    if (kinship === 'T') {
      return 'Titular';
    }

    if (kinship === 'C') {
      return 'Cônjuge';
    }

    return 'Filho';
  }
}
