import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CustomInput } from '../CustomInput';

@Component({
  selector: 'app-select-coverage',
  templateUrl: './select-coverage.component.html',
  styleUrls: ['./select-coverage.component.scss'],
})
export class SelectCoverageComponent extends CustomInput implements OnInit {
  label = 'Cobertura';

  constructor(@Self() @Optional() ngControl: NgControl) {
    super(ngControl);
  }

  override ngOnInit(): void {
    this.registerChanges();
  }
}
