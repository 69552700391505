import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpService {
  private _userIp$ = new BehaviorSubject<string | undefined>(undefined);

  get userIp$(): Observable<string> {
    return this._userIp$.pipe(
      switchMap((value) => (value ? of(value) : this.getUserIp()))
    );
  }

  getUserIp(): Observable<string> {
    return from(this.getAsyncUserIP()).pipe(
      tap((ip) => this._userIp$.next(ip))
    );
  }

  private async getAsyncUserIP(): Promise<string> {
    const url = 'https://jsonip.com';
    const response = await fetch(url, { mode: 'cors' });
    const json: { ip: string } = await response.json();
    return json.ip;
  }
}
