<div
  class="dialog-container animate__animated animate__fadeIn animate__faster"
  *ngIf="showCartPopup$ | async"
>
  <mat-icon
    class="close-icon"
    mat-dialog-close
    (click)="hideCartPopup()"
    matTooltip="Fechar carrinho"
    matTooltipPosition="before"
  >
    close
  </mat-icon>
  <h3 class="default-title-3 text-center plan-row-title mt-2" mat-dialog-title>
    MEU CARRINHO
  </h3>
  <div
    class="d-flex align-items-center flex-column my-5"
    *ngIf="isLoadingCart$ | async"
  >
    <mat-spinner
      [diameter]="50"
      [strokeWidth]="7"
      value="Carregando..."
    ></mat-spinner>
    <p class="text-muted mt-3">Carregando...</p>
  </div>
  <ng-container *ngIf="(isLoadingCart$ | async) === false">
    <mat-card
      *ngFor="let cartGroup of (cart$ | async)?.shoppingCartItems"
      class="cart-group-container mat-elevation-z1"
    >
      <p class="cart-group-title">
        Operadora:
        <span class="plan-row-text">
          {{ cartGroup.shoppingCartItemDetails[0].provider }}
        </span>
      </p>

      <p class="cart-group-title">
        Entidade:
        <span class="plan-row-text">
          {{ cartGroup.shoppingCartItemDetails[0].groupCode }}
        </span>
      </p>

      <p class="cart-group-title">
        Porte:
        <span class="plan-row-text">
          De {{ cartGroup.initialRange }} a {{ cartGroup.finalRange }} vidas
        </span>
      </p>

      <div *ngFor="let plan of cartGroup.shoppingCartItemDetails">
        <mat-icon
          class="close-plan-icon"
          (click)="onRemovePlanCart(plan.id)"
          matTooltip="Remover do carrinho"
          matTooltipPosition="before"
        >
          delete
        </mat-icon>

        <div class="dialog-content mt-2 mat-elevation-z1">
          <p class="plan-row-title">
            Plano:
            <span class="plan-row-text"> {{ plan.planName }} </span>
          </p>

          <div class="d-flex">
            <p class="plan-row-title me-3">
              Vidas:
              <span class="plan-row-text"> {{ plan.lifeAmount }} </span>
            </p>
            <p class="plan-row-title text-center">
              Valor Total/Mês:
              <span class="plan-row-text">
                {{ plan.value | currency }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="total-value-container">
      <p *ngIf="(cart$ | async)?.shoppingCartItems?.length">
        Valor Total da Compra:
        <strong>{{ totalCartValue$ | async | currency }}</strong>
      </p>
    </div>

    <div
      class="d-flex justify-content-center align-items-center my-5 empty-cart-container"
      *ngIf="isEmptyCart"
    >
      <span class="text-muted"> CARRINHO VAZIO </span>
    </div>
  </ng-container>

  <div class="buttons-container mt-3" mat-dialog-actions>
    <app-button
      class="ms-3"
      [disabled]="isEmptyCart"
      label="Contratar"
      (click)="onGetPlanClick()"
    ></app-button>
    <app-button
      [disabled]="isEmptyCart"
      label="Limpar carrinho"
      color="secondary"
      (click)="clearShoppingCart()"
    ></app-button>
  </div>
</div>
