<div class="request-pass-container">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-card class="m-4 shadow">
      <h1 class="request-pass-title">Esqueci minha senha</h1>

      <p class="text-muted mb-3">Simulador de planos</p>

      <small class="text-default" *ngIf="accessType === 1">
        Informe o CPF cadastrado, para receber um e-mail com seu login e senha.
      </small>

      <small class="text-default" *ngIf="accessType === 2">
        Informe o e-mail cadastrado, para receber um link e redefinir sua senha.
      </small>

      <!-- Campo exclusivo para corretor -->
      <mat-form-field
        appearance="outline"
        class="w-100 mt-2"
        *ngIf="accessType === 1"
      >
        <mat-label>CPF</mat-label>
        <input
          matInput
          type="text"
          inputmode="numeric"
          formControlName="cpf"
          mask="000.000.000-00"
        />
        <mat-icon
          [class.text-success]="formGroup.controls.cpf.valid"
          *ngIf="formGroup.controls.cpf.value"
          matSuffix
        >
          {{ formGroup.controls.cpf.hasError("invalidCpf") ? "close" : "done" }}
        </mat-icon>

        <mat-error *ngIf="formGroup.controls.cpf.hasError('required')">
          CPF é obrigatório
        </mat-error>

        <mat-error *ngIf="formGroup.controls.cpf.hasError('invalidCpf')">
          Digite um CPF válido
        </mat-error>
      </mat-form-field>

      <!-- Campo exclusivo para cliente -->
      <mat-form-field
        appearance="outline"
        class="w-100 mt-2"
        *ngIf="accessType === 2"
      >
        <mat-label>E-mail</mat-label>
        <input matInput type="email" formControlName="email" />
        <mat-icon
          [class.text-success]="formGroup.controls.email.valid"
          *ngIf="formGroup.controls.email.value"
          matSuffix
        >
          email
        </mat-icon>

        <mat-error *ngIf="formGroup.controls.email.hasError('required')">
          E-mail é obrigatório
        </mat-error>

        <mat-error *ngIf="formGroup.controls.email.hasError('email')">
          Digite um e-mail válido
        </mat-error>
      </mat-form-field>

      <div class="d-flex justify-content-between align-items-center">
        <a routerLink="/autenticacao/entrar" class="a-tag-link a-tag-link-text">
          Lembrou a senha?
        </a>
        <app-button type="submit" label="Enviar"></app-button>
      </div>
    </mat-card>
  </form>
</div>
