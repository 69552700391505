import { createAction, props } from '@ngrx/store';
import { Prop } from 'src/app/core/models/Prop';
import { City } from 'src/app/models/City';
import { State } from 'src/app/models/State';

enum InputsActions {
  ClearInputsState = '[Inputs] Clear Inputs State',

  GetUFsRequest = '[Inputs] Get UFs Request',
  GetUFsSuccess = '[Inputs] Get UFs Success',

  GetCitiesRequest = '[Inputs] Get Cities Request',
  GetCitiesSuccess = '[Inputs] Get Cities Success',
  GetCitiesError = '[Inputs] Get Cities Error',
}

export const clearInputsState = createAction(InputsActions.ClearInputsState);

export const getUFsRequest = createAction(InputsActions.GetUFsRequest);

export const getUFsSuccess = createAction(
  InputsActions.GetUFsSuccess,
  props<Prop<State[]>>()
);

export const getCitiesRequest = createAction(
  InputsActions.GetCitiesRequest,
  props<Prop<State>>()
);

export const getCitiesSuccess = createAction(
  InputsActions.GetCitiesSuccess,
  props<Prop<City[]>>()
);

export const getCitiesError = createAction(InputsActions.GetCitiesError);
